// This file is what will yield our root component <App />
// It's only imported by client/src/index.js and wrapped with the ApolloProvider
// configured in it. That means Apollo will be available everywhere
// Now this top-level <App /> component needs to be able to render any page of the app
// and render only one of them based on the URL requested.
// There are various way to do this but typically, you will use react-router-dom (see below)
// which is the most popular React router. A router is something that keeps your UI in sync with
// the URL a user is visiting. React Router does it by nesting pages components (Like DashboardPage)
// inside of <Route> components with various path= props.
// React Router will only render components nested in a <Route> with a matching path
// To set it up, we import all the routes we need for the app (constants/routes.js file)
// and for each route, we nest the corresponding page component after importing it.
// That's why you see so many import below. We need to import every single page of the app here
// (each page will import further components).
// It's nice to import all pages in a single file because that gives us an overview of all the pages
// the app includes (they're all here.)
//
// Here, you will see a small peculiarity: we import both routes and appRoutes. That's because we have some routes
// which need to be scoped by the app subdomain (like the dashboard) and some not (like the store pages)
//
// That's because in theory, you can have a shop called 'dashboard' and you then have:
// https://app.yourzengift.com/dashboard -> dashboard of the YZG app
// https://yourzengift.com/dashboard -> store page of the shop called 'dashboard'
//
// We'll probably want to exclude all shop names that we may want
// to use as future app routes or at least check when we add an app route that
// it's not used by any shop.
//
// To check if the route is one from the app subdomain, we check:
// Object.values(appRoutes).includes(match.path)
//
// If it's one of the app routes, and it's not served from the app subdomain,
// we redirect to the app subdomain.
// If it's not one of the app routes (shop pages, order pages, etc.)
// we don't redirect.
import React from 'react';
import { AUTH_TOKEN_KEY } from '../../constants/auth';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

// Importing all pages of the app below..
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';

import DashboardPage from '../Dashboard';
import GiftCardsIndexPage from '../GiftCard';
import GiftCardsNewPage from '../GiftCard/new';
import ShareLinksPage from '../ShareLinks';
import GiftCardsPurchaseList from '../PurchasePage/index';
import QuickRedeemPage from '../QuickRedeem';
import OnboardingStepOnePage from '../Onboarding/stepOne';
import OnboardingStepTwoPage from '../Onboarding/stepTwo';
import FacebookSharePage from '../Onboarding/facebookShare';
import LinkedInSharePage from '../Onboarding/linkedinShare';
import TwitterSharePage from '../Onboarding/twitterShare';
import InstagramSharePage from '../Onboarding/instagramShare';
import ShareLinkPage from '../Onboarding/urlShare';
import SettingsPage from '../Settings';
import NewOrderPage from '../Orders/NewOrderPage';
import StorePage from '../PurchasePage/StorePage';
import OrderPaymentPage from '../PurchasePage/OrderPaymentPage';
import LoginLayout from '../Layouts/LoginLayout';
import MainLayout from '../Layouts/MainLayout';
import OnboardingLayout from '../Layouts/OnboardingLayout';
import OrderList from '../Orders/index';
import OrderPage from '../Orders/OrderPage';
import PurchasePageHeader from '../Layouts/PurchasePageHeader';
import CompanySettingsPage from '../Settings/CompanySettingsPage';
import PaymentSettingsPage from '../Settings/PaymentSettingsPage';
import PurchasePageSettings from '../Settings/purchasePageSettings';
import EmailTemplateSettings from '../Settings/emailSetting';
import EditGiftCard from '../GiftCard/edit';
import NewPasswordPage from '../PasswordReset/newPassword';
import PasswordRecoveryRequestPage from '../PasswordReset/recoveryRequest';
import PrivacyPolicy from '../Policies/privacy';
// See routing info above
import * as routes from '../../constants/routes';
import * as appRoutes from '../../constants/appRoutes';

// history is used by the router to be able to navigate back to past pages
import history from '../../constants/history';

function isAuthenticated() {
  return localStorage.getItem(AUTH_TOKEN_KEY);
}

// AppRoute is a custom higher order component of the router's <Route> component
// If the path the user wants to access is in the list of the protected ones, it checks if the
// user is authenticated by checking if there is a session token in localStorage.
// If not, it redirects to the login page.
const AppRoute = ({ layout: Layout, ...rest }) => {
  return (
    <Route {...rest}>
      {({ match }) => {
        if (match) {
          console.log(
            `React Router found a match from the current path: '${match.path}'. Rendering the corresponding component (${rest.children.type.name})`,
          );
        }
        let protectedPaths = [
          '/dashboard',
          '/welcome',
          '/get-paid',
          '/facebook-share',
          '/linkedin-share',
          '/twitter-share',
          '/instagram-share',
          '/your-link',
          '/gift-cards',
          '/gift-cards/new',
          '/share-links',
          '/facebook-promotion',
          '/reports',
          '/settings',
          '/settings/purchase-page',
          '/settings/email-settings',
          '/settings/company',
          '/settings/payment',
          '/my-orders',
          '/password-reset',
          '/new-password',
          '/privacy-policy',
        ];

        if (
          match &&
          protectedPaths.includes(match.path) &&
          !isAuthenticated()
        ) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                search: `return=${match.path}`,
                state: { flash: 'Please sign in' },
              }}
            />
          );
        } else if (window.location.hostname === 'localhost') {
          console.log(
            'Redirecting to app.yourzengift.local. Make sure it is included in your host file',
          );
          window.location.replace(
            'http://app.yourzengift.local:3000',
          );
          return;
        } else if (
          match &&
          Object.values(appRoutes).includes(match.path) &&
          window.location.hostname.split('.').length == 2
        ) {
          console.log(
            'This route is an app route - redirecting to the app subdomain',
          );
          process.env.NODE_ENV === 'development'
            ? window.location.replace(
                `http://app.${window.location.hostname}:3000${match.path}`,
              )
            : window.location.replace(
                `https://app.yourzengift.com${match.path}`,
              );
          return;
        }
        // CSSTransition is a wrapper that automatically adds css classes to
        // components as they appear and disappear from the dom. This allows for
        // nicer/smoother transition between pages (rather than having the components
        // blink in & out.)
        return (
          <CSSTransition
            in={match != null}
            timeout={300}
            classNames="page"
            unmountOnExit
          >
            <Layout>{rest.children}</Layout>
          </CSSTransition>
        );
      }}
    </Route>
  );
};

const App = ({ session, refetch }) => (
  <div className="layout-wrapper">
    <Router history={history}>
      <>
        <Switch>
          <Route exact path={Object.values(appRoutes)}>
            <AppRoute path={routes.SIGN_UP} layout={LoginLayout}>
              <SignUpPage refetch={refetch} />
            </AppRoute>
            <AppRoute path={routes.SIGN_IN} layout={LoginLayout}>
              <SignInPage refetch={refetch} />
            </AppRoute>
            <AppRoute path={routes.NEW_PASSWORD} layout={LoginLayout}>
              <NewPasswordPage refetch={refetch} />
            </AppRoute>
            <AppRoute
              path={routes.PASSWORD_RESET}
              layout={LoginLayout}
            >
              <PasswordRecoveryRequestPage refetch={refetch} />
            </AppRoute>

            <AppRoute
              exact
              path={routes.DASHBOARD}
              layout={MainLayout}
            >
              <DashboardPage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.GIFT_CARDS}
              layout={MainLayout}
            >
              <GiftCardsIndexPage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.NEW_GIFT_CARD}
              layout={MainLayout}
            >
              <GiftCardsNewPage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.SHARE_LINKS}
              layout={MainLayout}
            >
              <ShareLinksPage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.EDIT_CARD}
              layout={MainLayout}
            >
              <EditGiftCard />
            </AppRoute>
            <AppRoute
              exact
              path={routes.SETTINGS}
              layout={MainLayout}
            >
              <SettingsPage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.COMPANY_SETTINGS}
              layout={MainLayout}
            >
              <CompanySettingsPage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.PAYMENT_SETTINGS}
              layout={MainLayout}
            >
              <PaymentSettingsPage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.PURCHASE_PAGE_SETTINGS}
              layout={MainLayout}
            >
              <PurchasePageSettings />
            </AppRoute>
            <AppRoute
              exact
              path={routes.EMAIL_SETTINGS}
              layout={MainLayout}
            >
              <EmailTemplateSettings />
            </AppRoute>

            <AppRoute
              exact
              path={routes.PRIVACY_POLICY}
              layout={OnboardingLayout}
            >
              <PrivacyPolicy />
            </AppRoute>
            <AppRoute
              exact
              path={routes.ONBOARDING_STEP_ONE}
              layout={OnboardingLayout}
            >
              <OnboardingStepOnePage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.ONBOARDING_STEP_TWO}
              layout={OnboardingLayout}
            >
              <OnboardingStepTwoPage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.FACEBOOK_SHARE}
              layout={OnboardingLayout}
            >
              <FacebookSharePage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.LINKEDIN_SHARE}
              layout={OnboardingLayout}
            >
              <LinkedInSharePage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.TWITTER_SHARE}
              layout={OnboardingLayout}
            >
              <TwitterSharePage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.INSTAGRAM_SHARE}
              layout={OnboardingLayout}
            >
              <InstagramSharePage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.URL_SHARE}
              layout={OnboardingLayout}
            >
              <ShareLinkPage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.QUICK_REDEEM}
              layout={MainLayout}
            >
              <QuickRedeemPage />
            </AppRoute>
            <AppRoute
              exact
              path={routes.PURCHASE_PAGE_LIST}
              layout={OnboardingLayout}
            >
              <GiftCardsPurchaseList />
            </AppRoute>
            <AppRoute
              exact
              path={routes.ORDER_LIST}
              layout={MainLayout}
            >
              <OrderList />
            </AppRoute>
            <AppRoute exact path={routes.ORDER} layout={MainLayout}>
              <OrderPage />
            </AppRoute>
          </Route>
          <AppRoute
            exact
            path={routes.ORDER_PAYMENT_PAGE}
            layout={OnboardingLayout}
          >
            <OrderPaymentPage />
          </AppRoute>
          <AppRoute
            exact
            path={routes.STORE_PAGE}
            layout={PurchasePageHeader}
          >
            <StorePage />
          </AppRoute>
          <AppRoute
            exact
            path={routes.NEW_ORDER_PATH}
            layout={OnboardingLayout}
          >
            <NewOrderPage />
          </AppRoute>
        </Switch>
      </>
    </Router>
  </div>
);

export default App;
