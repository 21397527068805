import React from 'react';
import * as routes from '../../constants/routes';

import Header from './Header';

import { Link } from 'react-router-dom';
import Footer from './Footer';

export default function MainLayout(props) {
  return (
    <div>
      <Header />
      <main className="layout-body">
        <nav className="sidebar trend-white">
          <ul>
            <li>
              <Link to={routes.DASHBOARD}>
                <span>
                  <span role="img" aria-label="Menu Option">
                    🎛️{' '}
                  </span>
                  Dashboard
                </span>
              </Link>
            </li>
            <li>
              <Link to={routes.GIFT_CARDS}>
                <span>
                  <span role="img" aria-label="Menu Option">
                    🎁{' '}
                  </span>
                  Gift Cards
                </span>
              </Link>
            </li>
            <li>
              <Link to={routes.SHARE_LINKS}>
                <span>
                  <span role="img" aria-label="Menu Option">
                    👋{' '}
                  </span>
                  Share (Links & Buttons)
                </span>
              </Link>
            </li>
            <li>
              <Link to={routes.ORDER_LIST}>
                <span>
                  <span role="img" aria-label="Menu Option">
                    📜{' '}
                  </span>
                  My Orders
                </span>
              </Link>
            </li>
            <li>
              <Link to={routes.SETTINGS}>
                <span>
                  <span role="img" aria-label="Menu Option">
                    🔨{' '}
                  </span>
                  Settings
                </span>
              </Link>
            </li>
          </ul>
        </nav>
        <div className="layout-content">{props.children}</div>
        <aside className="sidepanel">.</aside>
      </main>
      <Footer />
    </div>
  );
}
