import React, { useEffect, useRef } from 'react';
import * as routes from '../../constants/routes';
import { Link } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { GET_MY_SHARELINK } from '../../queries/currentUser';
import linkify from '../../lib/linkify';
import OnboardingRedirector from './OnboardingRedirector';
import { successToast, errorToast } from '../../constants/toasts';

export default function ShareLinkPage(props) {
  const COMPLETE_ONBOARDING_STEP = gql`
    mutation completeOnboardingStep($step: Int!) {
      completeOnboardingStep(step: $step) {
        id
      }
    }
  `;
  const visitedStep = 3;
  const [mutation, _] = useMutation(COMPLETE_ONBOARDING_STEP);

  function handleOnboardingKeyDown(event) {
    let key = event.keyCode || event.charCode || 0;

    if ([13, 39].indexOf(key) !== -1) {
      document.querySelectorAll('.next-button')[0].click();
    } else if ([37].indexOf(key) !== -1) {
      document.querySelectorAll('.back-button')[0].click();
    }
  }

  useEffect(() => {
    document.body.addEventListener(
      'keydown',
      handleOnboardingKeyDown,
      null,
    );

    mutation({ variables: { step: visitedStep } });

    return function cleanup() {
      document.body.removeEventListener(
        'keydown',
        handleOnboardingKeyDown,
      );
    };
  }, []);

  const { loading, error, data } = useQuery(GET_MY_SHARELINK);
  const shareBtnRef = useRef();
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const shareLink = linkify(data.me.company.purchasePage.shareLink);
  function clickToCopy() {
    console.log(shareBtnRef);
    const el = document.createElement('textarea');
    el.value = shareLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    shareBtnRef.current.innerHTML = `<span >
        <i class="fas fa-check"></i> Copied!
      </span>
    `;
    setTimeout(() => {
      shareBtnRef.current.innerHTML = `Click to Copy`;
    }, 2000);
  }
  return (
    <div className="is-clearfix with-shadow onboarding-card">
      <div className="columns">
        <div className="column is-7 content-column">
          <h2 className="title is-4">
            Alright it's sharing time! Here's the direct link to your
            new gift card shop
          </h2>
          <h4 className="subtitle is-5">
            We highly recommend sending an email to your list with the
            offering below.
          </h4>

          <label style={{ display: 'block', fontWeight: 'bold' }}>
            Link to share
          </label>
          <span style={{ fontFamily: 'monospace' }}>
            <a href={shareLink}>{shareLink}</a>
          </span>
          <button
            className="button is-success"
            onClick={() => clickToCopy()}
            ref={shareBtnRef}
          >
            Click to Copy
          </button>
          <br />
          <br />
        </div>
        <div className="column is-5"></div>
      </div>

      <Link
        to={routes.ONBOARDING_STEP_TWO}
        className="back-button button is-small"
      >
        BACK
      </Link>

      <Link
        to={routes.FACEBOOK_SHARE}
        className="next-button button is-small is-primary is-pulled-right"
      >
        NEXT
      </Link>
      <OnboardingRedirector
        userStep={data.me.currentOnboardingStep}
        visitedStep={visitedStep}
      />
    </div>
  );
}
