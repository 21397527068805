import React, { useEffect, useState, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import Confetti from 'react-confetti';
import { gql, useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import OrdersTable from './OrdersTable';
import WelcomeMessage from './WelcomeMessage';
import { useWindowSize } from '@react-hook/window-size';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
import centsToValueString from '../../lib/centsToValueString';
import RevenueChart from './RevenueChart';

export default function Dashboard(props) {
  const REVENUE_DATA = gql`
    query GetRevenueData {
      me {
        id
        currentOnboardingStep
        company {
          todaysRevenue
          yesterdaysRevenue
          currentMonthRevenue
          paidOrdersCount
          totalRevenue
        }
      }
      myOrders {
        id
        code
        giftCard {
          name
        }
        createdAt
        value
        price
        purchaserEmail
        recipientEmail
      }
    }
  `;

  const COMPLETE_ONBOARDING_STEP = gql`
    mutation completeOnboardingStep($step: Int!) {
      completeOnboardingStep(step: $step) {
        id
      }
    }
  `;

  const { loading, error, data } = useQuery(REVENUE_DATA);
  const [mutation] = useMutation(COMPLETE_ONBOARDING_STEP, {
    ignoreResults: true,
  });

  const [confettiRunning, setConfettiRunning] = useState(true);
  const [width, height] = useWindowSize();

  const showWelcomeMessage = useRef(false);

  if (data && data.me && data.me.currentOnboardingStep === 7) {
    showWelcomeMessage.current = true;
  }

  useEffect(() => {
    setTimeout(function () {
      if (data && data.me && data.me.currentOnboardingStep === 7) {
        setConfettiRunning(false);
        mutation({ variables: { step: 8 } });
      }
    }, 5000);
  });

  const chartDataGenerator = (data, axis) => {
    switch (axis) {
      case 'x':
        return data.map((day) =>
          moment(day.referenceDate).format('ddd - MM/DD'),
        );

      case 'y':
        return data.map((day) => day.metricValue);
      default:
    }
  };
  let generateChartData = (data) => {
    return {
      xAxis: {
        type: 'category',
        data: chartDataGenerator(data, 'x'),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: chartDataGenerator(data, 'y'),
          color: '#fedc31',
          type: 'bar',
        },
      ],
      tooltip: { show: true, formatter: '{b}: ${c}' },
    };
  };

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  return (
    <div className="pb-4">
      {showWelcomeMessage.current && <WelcomeMessage />}
      <h3 className="title is-3">Sales Overview</h3>
      <RevenueChart />
      <div className="columns">
        <div className="column">
          <div className="card">
            <div className="card-content">
              <h4 className="subtitle is-5">Today</h4>
              <span className="title is-3">
                {centsToValueString(data.me.company.todaysRevenue)}
              </span>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <div className="card-content">
              <h4 className="subtitle is-5">Yesterday</h4>
              <span className="title is-3">
                {centsToValueString(
                  data.me.company.yesterdaysRevenue,
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <div className="card-content">
              <h4 className="subtitle is-5">This month</h4>
              <span className="title is-3">
                {centsToValueString(
                  data.me.company.currentMonthRevenue,
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="card">
            <div className="card-content">
              <h4 className="subtitle is-5">Total Gift cards sold</h4>
              <span className="title is-3">
                {data.me.company.paidOrdersCount}
              </span>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <div className="card-content">
              <h4 className="subtitle is-5">Total Revenue</h4>
              <span className="title is-3">
                {centsToValueString(data.me.company.totalRevenue)}
              </span>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="card">
            <div className="card-content">
              <h4 className="subtitle is-5">Average Revenue</h4>
              <span className="title is-3">
                $
                {(data.me.company.totalRevenue / 100) *
                  data.me.company.paidOrdersCount}
              </span>
            </div>
          </div>
        </div>
      </div>
      {data.myOrders.length > 1 ? (
        <div>
          <Link className="is-pulled-right" to={routes.ORDER_LIST}>
            View All
          </Link>
        </div>
      ) : (
        <div></div>
      )}
      <h4 className="subtitle is-5">Recent Orders</h4>
      <OrdersTable orders={data.myOrders} />
      {data.me.currentOnboardingStep === 7 && (
        <Confetti
          recycle={confettiRunning}
          width={width}
          height={height}
        />
      )}
    </div>
  );
}
