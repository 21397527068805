import React from 'react';
import * as routes from '../../constants/routes';
import linkify from '../../lib/linkify';

export default function ConnectStripeMotivator(props) {
  if (props.stripeActive) {
    return (
      <div className="box card" style={{ opacity: 0.5 }}>
        Connect your Stripe account (Done)
      </div>
    );
  }
  return (
    <a href={linkify(routes.PAYMENT_SETTINGS, true)}>
      <div className="box card">
        <strong>TO DO:</strong> <br />
        Connect your Stripe account
        <br /> In order for people to purchase your cards and for you
        to get paid, you must connect your stripe account.
      </div>
    </a>
  );
}
