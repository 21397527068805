import React, { useEffect } from 'react';
import linkify from '../../lib/linkify';
import facebookF from '../../assets/images/facebook-f-small.png';
import CheckedGatewayShareButton from '../../lib/isGateWayActive';
export default function FacebookSharePrompt(props) {
  const appId = '676342903229135';
  const hereLink = linkify('facebook-share');
  const quote =
    'Give the gift of a clean home! - click the link below!';
  const hashtag = '#zengift';
  const { shareLink, gateStatus } = props;
  return (
    <div>
      <meta
        property="og:image"
        itemprop="image"
        content={linkify(
          `media/social-media-share/${
            Math.floor(Math.random() * 3) + 1
          }.png`,
        )}
      />
      <h2 className="title is-4">
        Your friends and family on Facebook already know, love and
        trust you
      </h2>
      <h4 className="subtitle is-5">
        They're the most likely to support you and your business
        through gift cards
      </h4>
      <CheckedGatewayShareButton
        el={
          <a
            href={`https://www.facebook.com/dialog/share?app_id=${appId}&display=popup&href=${shareLink}&redirect_uri=${hereLink}&quote=${quote}&hashtag=${hashtag}`}
            target="_blank"
            rel="noopener noreferrer"
            className="with-embedded-img button with-shadow"
          >
            <img src={facebookF} alt="Facebook Logo" />
            <span>Share on Facebook</span>
          </a>
        }
        gateStatus={gateStatus}
      />
    </div>
  );
}
