import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import appLogo from '../../assets/images/zengift-logo.png';
import { AUTH_TOKEN_KEY } from '../../constants/auth';

import * as routes from '../../constants/routes';

import history from '../../constants/history';

export default function SignInPage(props) {
  const SIGN_IN = gql`
    mutation SignIn($signInInput: SignInInput!) {
      signIn(signInInput: $signInInput) {
        token
      }
    }
  `;

  const [displayError, setDisplayError] = useState('');

  const [mutation] = useMutation(SIGN_IN, {
    onCompleted(data) {
      let token = data.signIn.token;
      if (token) {
        localStorage.setItem(AUTH_TOKEN_KEY, token);
        history.push(routes.DASHBOARD);
      }
    },
    onError(res) {
      setDisplayError(res);
    },
  });

  const { handleSubmit, register, errors } = useForm();
  const onSubmit = (values) => {
    mutation({
      variables: {
        signInInput: {
          email: values.email,
          password: values.password,
        },
      },
    });
  };

  return (
    <div className="sign-in-card">
      <div className="logo-container with-shadow">
        <img src={appLogo} alt="App Logo" />
      </div>
      <h3 className="title is-2">Sign In to your account</h3>
      <h4 className="subtitle">
        Or start{' '}
        <Link to={routes.SIGN_UP}>your 14-day free trial</Link>
      </h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <input
            name="email"
            placeholder="your.email@example.com"
            ref={register({
              required: 'Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'invalid email address',
              },
            })}
          />
          {errors.email && errors.email.message}
        </div>

        <input
          type="password"
          name="password"
          placeholder="••••••••••••••"
          ref={register({})}
        />
        {errors.password && errors.password.message}
        <Link to={routes.PASSWORD_RESET}>Reset password</Link>
        <div>
          {displayError && displayError.message}
          <button className="button is-primary" type="submit">
            Sign In
          </button>
        </div>
      </form>
    </div>
  );
}
