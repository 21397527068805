import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as routes from '../../constants/routes';
import history from '../../constants/history';
import linkify from '../../lib/linkify';
import valueStringToCents from '../../lib/valueStringToCents';
import centsToValueString from '../../lib/centsToValueString';
import GiftCardPreview from './GiftCardPreview';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import { successToast, errorToast } from '../../constants/toasts';

function EditGiftCard({ session }) {
  const EDIT_GIFT_CARD = gql`
    mutation EditGiftCard($editGiftCardInput: EditGiftCardInput!) {
      editGiftCard(editGiftCardInput: $editGiftCardInput) {
        id
      }
    }
  `;
  const GET_GIFT_CARD = gql`
    query GiftCard($id: ID!) {
      giftCard(id: $id) {
        id
        name
        value
        price
        description
        additionalInfoUrl
        backgroundColor
        backgroundImageUrl
        textColor
        textFont
        orders {
          id
        }
      }
    }
  `;

  const { uuid } = useParams();
  const { loading, error, data } = useQuery(GET_GIFT_CARD, {
    variables: { id: uuid },
  });
  const [mutation] = useMutation(EDIT_GIFT_CARD, {
    onCompleted() {
      successToast('Your card has been edited!');
      setTimeout(function () {
        history.push(routes.GIFT_CARDS);
      }, 3000);
    },
    onError(err) {
      errorToast(err);
    },
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    errors,
  } = useForm({
    criteriaMode: 'all',
    defaultValues: {
      backgroundImageUrl: linkify(
        `media/gift-card-default-background-1.png`,
      ),
    },
  });

  watch(['name', 'value', 'description']);
  let giftCardData = null;
  const onSubmit = async (values) => {
    if (values.price && values.value) {
      if (Number(values.price) > Number(values.value)) {
        errorToast(
          `The price (${values.price}) cannot be bigger than the value(${values.value}) of the card `,
        );
      } else {
        await mutation({
          variables: {
            editGiftCardInput: {
              id: giftCardData.id,
              name: values.name,
              price:
                valueStringToCents(values.price) ||
                giftCardData.price,
              value:
                valueStringToCents(values.value) ||
                giftCardData.value,
              description: values.description,
              additionalInfoUrl: values.additionalInfoUrl,
              backgroundImageUrl: values.backgroundImageUrl,
            },
          },
        })
          .then((res) => {
            successToast('Your card has been edited!');
            history.push(routes.GIFT_CARDS);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      await mutation({
        variables: {
          editGiftCardInput: {
            id: giftCardData.id,
            name: values.name,
            description: values.description,
            additionalInfoUrl: values.additionalInfoUrl,
            backgroundImageUrl: values.backgroundImageUrl,
          },
        },
      })
        .then((res) => {
          successToast('Your card has been edited!');
          history.push(routes.GIFT_CARDS);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function handleGiftCardClick(event) {
    const currentSelected = document.querySelector('.selectedCard');
    if (currentSelected)
      currentSelected.classList.remove('selectedCard');
    event.currentTarget.parentNode.classList.add('selectedCard');
    setValue([
      { backgroundImageUrl: event.currentTarget.children[0].src },
    ]);
  }
  if (error || loading) {
    if (error) {
      return `Error! ${error.message}`;
    } else return 'Loading...';
  }

  giftCardData = data.giftCard;
  let previewParams = Object.assign({}, getValues());
  previewParams.value = valueStringToCents(previewParams.value);
  const isEditable = giftCardData.orders.length == 0 ? true : false;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ToastContainer />
      <h3 className="title is-3">Edit Gift Card</h3>
      <div className="columns">
        <div className="column is-4">
          <p>Use this form to edit the card: {giftCardData.name}.</p>
          <br />
          <strong>Note:</strong> Once someone purchases the giftcard,
          you are unable to change the price and value of your
          giftcard
        </div>
        <div className="column is-8">
          <div className="field">
            <label className="label">Card Name</label>
            <input
              name="name"
              className="input"
              type="text"
              placeholder="Spring Clean Gift Card"
              defaultValue={giftCardData.name}
              minLength="5"
              maxLength="25"
              ref={register({
                required: 'Required',
                minLength: {
                  value: 5,
                  message:
                    'A giftcard name cannot be less than 5 characters',
                },
                maxLength: {
                  value: 25,
                  message:
                    'A giftcard name cannot be more than 25 characters',
                },
              })}
            />
            {errors.name && (
              <p className={'has-text-danger'}>
                {errors.name.message}
              </p>
            )}
          </div>
          <div className="field">
            <label className="label">
              Gift Card Price (amount charged)
            </label>
            {isEditable ? (
              <input
                name="price"
                className="input column is-2"
                type="number"
                min="5"
                max="1000"
                defaultValue={Math.floor(giftCardData.price / 100)}
                ref={register({
                  required: 'Required',
                  pattern: {
                    value: /[\0-9$]*/g,
                    message: 'Whole Numbers Only',
                  },
                  max: {
                    value: 1000,
                    message: 'A giftcard cannot be more that $1000',
                  },
                  min: {
                    value: 5,
                    message: 'A giftcard cannot be less than $5',
                  },
                })}
              />
            ) : (
              <div>
                <input
                  name="price"
                  type="hidden"
                  defaultValue={Math.floor(giftCardData.price / 100)}
                  ref={register({})}
                />
                {centsToValueString(giftCardData.price)}
              </div>
            )}
          </div>
          {errors.price && (
            <p className={'has-text-danger'}>
              {errors.price.message}
            </p>
          )}
          <div className="field">
            <label className="label">Gift Card Value</label>
            <div>
              {isEditable ? (
                <input
                  name="value"
                  className="input column is-2"
                  type="number"
                  min="5"
                  max="1000"
                  defaultValue={Math.floor(giftCardData.value / 100)}
                  ref={register({
                    required: 'Required',
                    pattern: {
                      value: /[\0-9$]*/g,
                      message: 'Whole Numbers Only',
                    },
                    max: {
                      value: 1000,
                      message: 'A giftcard cannot be more that $1000',
                    },
                    min: {
                      value: 5,
                      message: 'A giftcard cannot be less than $5',
                    },
                  })}
                />
              ) : (
                <div>
                  <input
                    name="value"
                    type="hidden"
                    defaultValue={Math.floor(
                      giftCardData.value / 100,
                    )}
                    ref={register({})}
                  />
                  {centsToValueString(giftCardData.value)}
                </div>
              )}
              {errors.value && (
                <p className={'has-text-danger'}>
                  {errors.value.message}
                </p>
              )}
            </div>
          </div>
          <div className="field">
            <label className="label">Description</label>

            <input
              name="description"
              className="input column is-6"
              type="text"
              placeholder="Gift card for the special spring offer"
              minLength="5"
              maxLength="35"
              defaultValue={giftCardData.description}
              ref={register({
                required: 'Required',
                minLength: {
                  value: 5,
                  message:
                    'A giftcard description cannot be less than 5 characters',
                },
                maxLength: {
                  value: 35,
                  message:
                    'A giftcard description cannot be more than 35 characters',
                },
              })}
            />
            {errors.description && (
              <p className={'has-text-danger'}>
                {errors.description.message}
              </p>
            )}
          </div>
          <div className="field">
            <label className="label">Additional Info URL</label>
            <input
              name="additionalInfoUrl"
              className="input"
              type="text"
              placeholder="http://mymaidservice.com"
              defaultValue={giftCardData.additionalInfoUrl}
              ref={register({
                pattern: {
                  value:
                    '_(?i)\b((?:https?|ftps?|mailto|wwwd{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}/)(?:[^s()<>]+|(([^s()<>]+|(([^s()<>]+)))*))+(?:(([^s()<>]+|(([^s()<>]+)))*)|[^s`!()[]{};:\'".,<>?«»“”‘’]))_iuS',
                  message: 'Must be a valid url',
                },
              })}
            />
            {errors.additionalInfoUrl && (
              <p className={'has-text-danger'}>
                {errors.additionalInfoUrl.message}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-5">
          <h4 className="title is-5">Pick a card design</h4>
          <div className="columns">
            <div className="column is-6">
              <figure
                className="image is-3by2 with-shadow"
                onClick={handleGiftCardClick}
              >
                <img
                  src={linkify(
                    `media/gift-card-default-background-1.png`,
                  )}
                  alt="GiftCard Option 1"
                />
              </figure>
            </div>
            <div className="column is-6">
              <figure
                className="image is-3by2 with-shadow"
                onClick={handleGiftCardClick}
              >
                <img
                  src={linkify(
                    `media/gift-card-default-background-2.png`,
                  )}
                  alt="GiftCard Option 1"
                />
              </figure>
            </div>
          </div>
          <div className="columns">
            <div className="column is-6">
              <figure
                className="image is-3by2 with-shadow"
                onClick={handleGiftCardClick}
              >
                <img
                  src={linkify(
                    `media/gift-card-default-background-3.png`,
                  )}
                  alt="GiftCard Option 1"
                />
              </figure>
            </div>
            <div className="column is-6">
              <figure
                className="image is-3by2 with-shadow"
                onClick={handleGiftCardClick}
              >
                <img
                  src={linkify(
                    `media/gift-card-default-background-4.png`,
                  )}
                  alt="GiftCard Option 1"
                />
              </figure>
            </div>
          </div>
        </div>

        <div className="column is-7" style={{ marginLeft: '30px' }}>
          <h4 className="title is-5">Preview of your gift card</h4>
          <GiftCardPreview giftCard={previewParams} />
          <input
            name="backgroundImageUrl"
            type="hidden"
            ref={register({ required: 'Required' })}
          />
        </div>
      </div>
      <button
        type="submit"
        className="button is-primary is-pulled-right"
      >
        Save
      </button>
      <br />
      <br />
      <br />
      <br />
    </form>
  );
}

export default EditGiftCard;
