import { gql } from '@apollo/client';
import {} from '@apollo/client';
export const GET_MYSELF_QUERY = gql`
  query GetMyself {
    me {
      id
      avatarUrl
      username
      company {
        name
      }
    }
  }
`;

export const GET_MY_SHARELINK = gql`
  query GetMyself {
    me {
      id
      currentOnboardingStep
      company {
        purchasePage {
          id
          shareLink
        }
        paymentGateway {
          isActive
        }
      }
    }
  }
`;

export const IS_PAYMENT_ACTIVE = gql`
  query GetMyself {
    me {
      company {
        paymentGateway {
          isActive
        }
      }
    }
  }
`;
