import React from 'react';

import '../../assets/styles/footer.scss'
const Footer = (props) => {
  return (
    <footer className="footer has-text-right">
      Made with ❤ by the ZenMaid Team || <a href={'https://www.zenmaid.com/terms-of-service/'}>©{' '}
      {`${new Date().getFullYear()}`} </a>
    </footer>
  );
};

export default Footer;
