import React from 'react';
import { gql, useMutation } from '@apollo/client';
import GiftCardForm from './GiftCardForm';

function NewGiftCard({ session }) {
  const CREATE_GIFT_CARD = gql`
    mutation CreateGiftCard($giftCardInput: GiftCardInput!) {
      createGiftCard(giftCardInput: $giftCardInput) {
        id
      }
    }
  `;

  const [mutation, { loading, error }] = useMutation(
    CREATE_GIFT_CARD,
    {
      onCompleted({ giftCard }) {
        console.log(giftCard);
      },
    },
  );

  if (loading) return <p>Loading</p>;
  if (error) return <p>An error occurred</p>;

  return <GiftCardForm mutation={mutation} />;
}

export default NewGiftCard;
