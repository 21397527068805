import React from 'react';
import valueStringToCents from '../../lib/valueStringToCents';
import centsToValueString from '../../lib/centsToValueString';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
export default function GiftCardPreview(props) {
  const baseCode = (
    <div>
      <img
        src={props.giftCard.backgroundImageUrl}
        className="with-shadow"
        alt="Card Style"
      />
      <span className="value-badge">
        {centsToValueString(props.giftCard.value)}
      </span>
      <h3>{props.giftCard.name || 'A Clean House GiftCard'}</h3>
      <h4>{props.giftCard.description}</h4>
    </div>
  );
  const purchasePageFormat = (
    <Link className="" to={props.giftCardUrl}>
      {' '}
      {baseCode}{' '}
    </Link>
  );

  return (
    <div className="gift-card-preview-wrapper">
      {props.giftCardUrl ? purchasePageFormat : baseCode}{' '}
    </div>
  );
}
