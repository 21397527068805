import React, { useEffect } from 'react';
import { GET_MY_SHARELINK } from '../../queries/currentUser';
import * as routes from '../../constants/routes';
import facebookShare from '../../assets/images/share-preview-facebook.png';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import linkify from '../../lib/linkify';
import OnboardingRedirector from './OnboardingRedirector';
import FacebookSharePrompt from '../ShareLinks/FacebookSharePrompt';

export default function FacebookSharePage(props) {
  const COMPLETE_ONBOARDING_STEP = gql`
    mutation completeOnboardingStep($step: Int!) {
      completeOnboardingStep(step: $step) {
        id
      }
    }
  `;
  const visitedStep = 4;
  function handleOnboardingKeyDown(event) {
    let key = event.keyCode || event.charCode || 0;

    if ([13, 39].indexOf(key) !== -1) {
      document.querySelectorAll('.next-button')[0].click();
    } else if ([37].indexOf(key) !== -1) {
      document.querySelectorAll('.back-button')[0].click();
    }
  }

  useEffect(() => {
    document.body.addEventListener(
      'keydown',
      handleOnboardingKeyDown,
      null,
    );

    mutation({ variables: { step: visitedStep } });

    return function cleanup() {
      document.body.removeEventListener(
        'keydown',
        handleOnboardingKeyDown,
      );
    };
  }, []);

  const { loading, error, data } = useQuery(GET_MY_SHARELINK);

  const [mutation, _] = useMutation(COMPLETE_ONBOARDING_STEP);

  if (loading) return 'Loading...';
  if (error) return `Error on Fetching ShareLink! ${error.message}`;

  const shareLink = linkify(data.me.company.purchasePage.shareLink);

  return (
    <div className="is-clearfix with-shadow onboarding-card">
      <meta
        property="og:image"
        itemprop="image"
        content={linkify(
          `media/social-media-share/${
            Math.floor(Math.random() * 3) + 1
          }.png`,
        )}
      />
      <div className="columns">
        <div className="column is-7 content-column">
          <FacebookSharePrompt
            shareLink={shareLink}
            gateStatus={data.me.company.paymentGateway.isActive}
          />
        </div>
        <div className="column is-5">
          <img
            height="800"
            src={facebookShare}
            alt="Facebook Share"
          />
        </div>
      </div>

      <Link
        to={routes.URL_SHARE}
        className="back-button button is-small"
      >
        BACK
      </Link>
      <Link
        to={routes.LINKEDIN_SHARE}
        className="next-button button is-small is-primary is-pulled-right"
      >
        NEXT
      </Link>
      <Link
        to={routes.TWITTER_SHARE}
        className="skip-button button is-small is-pulled-right mr-m"
      >
        SKIP
      </Link>
      <OnboardingRedirector
        userStep={data.me.currentOnboardingStep}
        visitedStep={visitedStep}
      />
    </div>
  );
}
