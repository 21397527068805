import { AUTH_TOKEN_KEY } from '../../constants/auth';
export const isLoggedIn = () => {
  console.log('getting token');

  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  console.log('token', token);
  if (token != null) {
    return true;
  } else return false;
};
export const isOnboarding = (path) => {
  if (
    path === '/welcome' ||
    path === '/get-paid' ||
    path === '/facebook-share' ||
    path === '/linkedin-share' ||
    path === '/twitter-share' ||
    path === '/instagram-share'
  ) {
    return true;
  } else return false;
};
export const linkLogo = () => {
  const loginCheck = isLoggedIn();
  if (loginCheck) {
    console.log('user is logged in');
    const isOnboardingPage = isOnboarding();
    return isOnboardingPage;
  } else {
    console.log('user is logged in');

    return false;
  }
};
