import React, { useEffect } from 'react';
import * as routes from '../../constants/routes';
import { GET_MY_SHARELINK } from '../../queries/currentUser';
import { Link } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import twitterShare from '../../assets/images/share-preview-twitter.png';
import linkify from '../../lib/linkify';
import TwitterSharePrompt from '../ShareLinks/TwitterSharePrompt';
import OnboardingRedirector from './OnboardingRedirector';

// const url = "http://www.yourzengift.com/twitter-share";

export default function TwitterSharePage(props) {
  const COMPLETE_ONBOARDING_STEP = gql`
    mutation completeOnboardingStep($step: Int!) {
      completeOnboardingStep(step: $step) {
        id
      }
    }
  `;
  const visitedStep = 6;
  const [mutation, _] = useMutation(COMPLETE_ONBOARDING_STEP);

  function handleOnboardingKeyDown(event) {
    let key = event.keyCode || event.charCode || 0;

    if ([13, 39].indexOf(key) !== -1) {
      document.querySelectorAll('.next-button')[0].click();
    } else if ([37].indexOf(key) !== -1) {
      document.querySelectorAll('.back-button')[0].click();
    }
  }

  useEffect(() => {
    document.body.addEventListener(
      'keydown',
      handleOnboardingKeyDown,
      null,
    );

    mutation({ variables: { step: visitedStep } });

    return function cleanup() {
      document.body.removeEventListener(
        'keydown',
        handleOnboardingKeyDown,
      );
    };
  }, []);

  const { loading, error, data } = useQuery(GET_MY_SHARELINK);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  const shareLink = linkify(data.me.company.purchasePage.shareLink);

  return (
    <div className="is-clearfix with-shadow onboarding-card">
      <div className="columns">
        <div className="column is-7 content-column">
          <TwitterSharePrompt
            shareLink={shareLink}
            gateStatus={data.me.company.paymentGateway.isActive}
          />
        </div>

        <div className="column is-5">
          <img
            height="800"
            src={twitterShare}
            alt="Twitter Share Button"
          />
        </div>
      </div>

      <Link
        to={routes.LINKEDIN_SHARE}
        className="back-button button is-small"
      >
        BACK
      </Link>
      <Link
        to={routes.INSTAGRAM_SHARE}
        className="next-button button is-small is-primary is-pulled-right"
      >
        NEXT
      </Link>
      <Link
        to={routes.INSTAGRAM_SHARE}
        className="skip-button button is-small is-pulled-right mr-m"
      >
        SKIP
      </Link>
      <OnboardingRedirector
        userStep={data.me.currentOnboardingStep}
        visitedStep={visitedStep}
      />
    </div>
  );
}
