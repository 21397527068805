import React, { useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';

export default function RevenueChart(props) {
  const MY_REVENUE_DATA_POINTS = gql`
    query MyRevenueDataPoints($fromDate: Date, $toDate: Date) {
      me {
        company {
          dataPoints(metricName: "daily_revenue", fromDate: $fromDate, toDate: $toDate) {
            referenceDate
            metricValue
          }
        }
      }
    }
  `;

  const [periodSelected, setPeriodSelected] = useState('week');
  const [chartFromDate, setChartFromDate] = useState(moment().startOf('week'));
  const [chartToDate, setChartToDate] = useState(moment().endOf('week'));

  const chartDataGenerator = (data, axis) => {
    switch (axis) {
      case 'x':
        return data.map((day) =>
          moment(day.referenceDate).format('ddd - MM/DD'),
        );
 
      case 'y':
        return data.map((day) => day.metricValue / 100.0);
      default:
    }
  };

  // Takes an array of dataPoints with keys metricValue / referenceDate
  // and formats the data to match EC Chart's requirements
  let generateChartData = (data) => {
    return {
      xAxis: {
        type: 'category',
        data: chartDataGenerator(data, 'x'),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: chartDataGenerator(data, 'y'),
          color: '#fedc31',
          type: 'bar',
        },
      ],
      tooltip: { show: true, formatter: '{b}: ${c}' }
    };
  };

  const handleClick = (periodKey) => {
    setPeriodSelected(periodKey);
    
    switch (periodKey) {
      case 'week':
        setChartFromDate(moment().startOf('week')) && setChartToDate(moment().endOf('week')) && refetch({
          fromDate: chartFromDate.format("YYYY-MM-DD"),
          toDate: chartToDate.format("YYYY-MM-DD")
        })
        break;
      case 'month':
        setChartFromDate(moment().startOf('month')) && setChartToDate(moment().endOf('month')) && refetch({
          fromDate: chartFromDate.format("YYYY-MM-DD"),
          toDate: chartToDate.format("YYYY-MM-DD")
        })
        break;

      default:
    }
  }

  const { loading, error, data, refetch } = useQuery(MY_REVENUE_DATA_POINTS, {
    variables: {
      fromDate: chartFromDate.format("YYYY-MM-DD"),
      toDate: chartToDate.format("YYYY-MM-DD")
    }
  });

  if (loading) {
    return (
      <div>
        <div className="buttons has-addons is-right">
          <button onClick={() => handleClick('week')} className="button is-active">Week</button>
          <button onClick={() => handleClick('month')} className="button">Month</button>
        </div>
        <div style={{minHeight: "240px"}}>
          <div id="chart">
            Loading..
          </div>
        </div>
      </div>
    )
  }

  if (error) return `Error! ${error.message}`;

  const dataPoints = data.me.company.dataPoints;
  let paddedDataPoints = [];

  for (let m = moment(chartFromDate); m.diff(chartToDate, 'days') <= 0; m.add(1, 'days')) {
    const foundDp = dataPoints.find(dp => moment(dp.referenceDate).format('YYYY-MM-DD') === m.format('YYYY-MM-DD'));
    paddedDataPoints.push(foundDp || { referenceDate: m.toDate(), metricValue: 0 })
  }

  const options = generateChartData(paddedDataPoints)

  return (
    <div>
      <div className="buttons has-addons is-right">
        <button onClick={() => handleClick('week')} className={`button ${periodSelected === 'week' && 'is-primary'}`}>Week</button>
        <button onClick={() => handleClick('month')} className={`button ${periodSelected === 'month' && 'is-primary'}`}>Month</button>
      </div>
      <div style={{minHeight: "240px"}}>
        <div id="chart">
          <ReactEcharts option={options} />
        </div>
      </div>
    </div>
  );
}
