// This is a helper method that will convert a given path to the correct
// absolute url with or without subdomain, in both prod and dev environments
export default (slug, withSubdomain) => {
  const slugWithoutSlash = (slug.charAt(0) == "/") ?
    slug.substring(1, slug.length) :
    slug

  if (withSubdomain) {
    return process.env.NODE_ENV === 'development'
      ? `http://app.yourzengift.local:3000/${slugWithoutSlash}`
      : `https://app.yourzengift.com/${slugWithoutSlash}`
  } else {  
    return process.env.NODE_ENV === 'development'
      ? `http://yourzengift.local:3000/${slugWithoutSlash}`
      : `https://yourzengift.com/${slugWithoutSlash}`
  }
};
