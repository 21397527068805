import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import * as routes from '../../constants/routes';
import history from '../../constants/history';
import { savedToast, errorToast } from '../../constants/toasts';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import FontPicker from 'font-picker-react';
import exampleImage from '../../assets/images/example-purchase-page.png';

export default function PurchasePageSettings(props) {
  const [bodyFont, setbodyFont] = useState(
    props.purchasePage.bodyFont,
  );
  const [menuFont, setmenuFont] = useState(
    props.purchasePage.menuFont,
  );
  const UPDATE_PURCHASE_PAGE = gql`
    mutation UpdateMyPurchasePage(
      $purchasePageInput: PurchasePageInput!
    ) {
      updateMyPurchasePage(purchasePageInput: $purchasePageInput) {
        id
      }
    }
  `;

  const [updatePurchasePageMutation] = useMutation(
    UPDATE_PURCHASE_PAGE,
    {
      onError(res) {
        console.log('Update Purchase Page Err', res);
      },
      refetchQueries: ['GetPurchasePage'],
    },
  );

  const purchasePage = props.purchasePage;

  const { handleSubmit, register } = useForm({
    defaultValues: {
      headerBackgroundColor: purchasePage.headerBackgroundColor,
      menuFont: purchasePage.menuFont,
      bodyFont: purchasePage.bodyFont,
      shareLink: purchasePage.shareLink,
      menuLink1Anchor:
        purchasePage.menuLinks[0] && purchasePage.menuLinks[0].anchor,
      menuLink1Url:
        purchasePage.menuLinks[0] && purchasePage.menuLinks[0].url,
      menuLink2Anchor:
        purchasePage.menuLinks[1] && purchasePage.menuLinks[1].anchor,
      menuLink2Url:
        purchasePage.menuLinks[1] && purchasePage.menuLinks[1].url,
    },
  });

  const onSubmit = (values) => {
    updatePurchasePageMutation({
      variables: {
        purchasePageInput: {
          headerBackgroundColor: values.headerBackgroundColor,
          menuFont: values.menuFont,
          bodyFont: values.bodyFont,
          shareLink: values.shareLink,
          menuLinks: [
            {
              url: values.menuLink1Url,
              anchor: values.menuLink1Anchor,
            },
            {
              url: values.menuLink2Url,
              anchor: values.menuLink2Anchor,
            },
          ],
        },
      },
    })
      .then(savedToast('👍 Settings Saved!'))
      .then(history.push(routes.PURCHASE_PAGE_SETTINGS))

      .catch((err) => {
        errorToast(err);
        console.log('Purchase Page Setting Update Err', err);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ToastContainer />
      <h3 className="title is-3">Update Purchase Page</h3>
      <div className="columns">
        <div className="column is-4">
          <p>
            Use this form to update your purchase page's appearance
            and associated links.
          </p>
          <img src={exampleImage}></img>
        </div>
        <div className="column is-8">
          <div className="field ">
            <label className="label ">Header Background Color</label>
            <input
              name="headerBackgroundColor"
              className="input column is-1 color-picker"
              type="color"
              placeholder="#00CCCC"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Menu Font</label>
            <FontPicker
              apiKey="AIzaSyDIe6DFGOYX4DPLbfnHu1O9NlSByqaCFY0"
              activeFontFamily={menuFont}
              onChange={(nextFont) => {
                setmenuFont(nextFont.family);
              }}
            />
            <input
              name="menuFont"
              className="input"
              type="hidden"
              value={menuFont}
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Body Font</label>
            <FontPicker
              apiKey="AIzaSyDIe6DFGOYX4DPLbfnHu1O9NlSByqaCFY0"
              activeFontFamily={bodyFont}
              onChange={(nextFont) => {
                setbodyFont(nextFont.family);
              }}
            />
            <input
              name="bodyFont"
              className="input"
              type="hidden"
              value={bodyFont}
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Your Gift Card Store Link</label>
            <div style={{ display: 'flex' }}>
              <div style={{ padding: '8px' }}>
                https://yourzengift.com/
              </div>
              <input
                name="shareLink"
                className="input"
                type="text"
                placeholder="maids-in-blue"
                ref={register({})}
              />
            </div>
          </div>
          <br />
          <h2 className="title is-5">Link 1</h2>
          <div className="field">
            <label className="label">Text</label>
            <input
              name="menuLink1Anchor"
              className="input"
              type="text"
              placeholder="Our premium services"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">URL</label>
            <input
              name="menuLink1Url"
              className="input"
              type="text"
              placeholder="Link address (URL) - e.g. yoursite.com/premium-services"
              pattern="^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?"
              ref={register({})}
            />
          </div>
          <br />
          <h2 className="title is-5">Link 2</h2>
          <div className="field">
            <label className="label">Text</label>
            <input
              name="menuLink2Anchor"
              className="input"
              type="text"
              placeholder="Check our special Summer Offer!"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">URL</label>
            <input
              name="menuLink2Url"
              className="input"
              type="text"
              pattern="^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?"
              placeholder="Link address (URL) - e.g. yoursite.com/summer-days"
              ref={register({})}
            />
          </div>
          <button type="submit" className="button is-primary">
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
