import React, { useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import OrderForm from './OrderForm';
import { useParams, Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

export default function NewOrderPage(props) {
  const { companySlug, giftCardSlug } = useParams();

  const NEW_ORDER_QUERY = gql`
    query NewOrderQuery(
      $companySlug: String!
      $giftCardSlug: String!
    ) {
      company(slug: $companySlug) {
        id
        name
        purchasePage {
          headerBackgroundColor
          menuFont
          bodyFont
        }
        giftCard(slug: $giftCardSlug) {
          id
          name
          slug
          price
          value
          backgroundImageUrl
          additionalInfoUrl
        }
      }
    }
  `;

  const { data } = useQuery(NEW_ORDER_QUERY, {
    variables: {
      companySlug: companySlug,
      giftCardSlug: giftCardSlug,
    },
  });

  const TRACK_VISIT_MUTATION = gql`
    mutation CreateCardVisitEvent($companySlug: String!) {
      createCardVisitEvent(companySlug: $companySlug)
    }
  `;
  const [trackCardVisit] = useMutation(TRACK_VISIT_MUTATION, {
    onError(res) {
      console.log('Tracking Visit Err', res);
    },
  });

  useEffect(() => {
    const domainString =
      process.env.NODE_ENV === 'development'
        ? 'yourzengift.local'
        : 'yourzengift.com';
    const companySlugCookie = Cookies.get('lastCardPageVisited', {
      domain: domainString,
    });

    if (companySlugCookie !== `${companySlug}.${giftCardSlug}`) {
      Cookies.set(
        'lastCardPageVisited',
        `${companySlug}.${giftCardSlug}`,
        {
          domain: domainString,
        },
      );
      trackCardVisit({
        variables: {
          companySlug: companySlug,
        },
      });
    }
  });

  if (!data) {
    return 'Loading';
  }

  return (
    <div>
      <div className="is-clearfix with-shadow purchase-page">
        <Link
          style={{
            position: 'relative',
            top: '18px',
            lineHeight: 1,
            display: 'block',
          }}
          to={`/${companySlug}`}
        >
          <FiArrowLeft style={{ padding: '4px 0 0 0' }} />
          All cards
        </Link>
        <h2 className="title is-4">
          Purchase Gift Card - {data.company.giftCard.name}
        </h2>
        <OrderForm data={data} />
      </div>
    </div>
  );
}
