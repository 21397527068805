import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
import GiftCardPreview from '../GiftCard/GiftCardPreview';
import '../../assets/styles/gift-cards.scss';

const GET_GIFT_CARDS = gql`
  query AllMyGiftCards {
    allMyGiftCards {
      id
      name
      isActive
      countOrders
      backgroundImageUrl
      value
      description
    }
  }
`;

const DEACTIVATE_GIFT_CARD = gql`
  mutation deactivateGiftCard($giftCardId: ID!) {
    deactivateGiftCard(giftCardId: $giftCardId) {
      id
    }
  }
`;
const ACTIVATE_GIFT_CARD = gql`
  mutation activateGiftCard($giftCardId: ID!) {
    activateGiftCard(giftCardId: $giftCardId) {
      id
    }
  }
`;

function GiftCards({ session }) {
  const [viewAll, setViewAll] = useState(false);
  const [deactivateGiftCard] = useMutation(DEACTIVATE_GIFT_CARD, {
    onCompleted() {
      refetch();
    },
    onError(res) {
      console.log('Deactivate Giftcard Err:', res);
    },
    refetchQueries: ['AllMyGiftCards'],
  });
  const [activateGiftCard] = useMutation(ACTIVATE_GIFT_CARD, {
    onCompleted() {
      refetch();
    },
    onError(res) {
      console.log('Activate Giftcard Err:', res);
    },
    refetchQueries: ['AllMyGiftCards'],
  });
  const onSubmitDeactivate = (id) => {
    deactivateGiftCard({
      variables: {
        giftCardId: id,
      },
    }).catch((err) => {
      console.log(err);
    });
  };
  const onSubmitActivate = (id) => {
    activateGiftCard({
      variables: {
        giftCardId: id,
      },
    }).catch((err) => {
      console.log(err);
    });
  };
  const { loading, error, data, refetch } = useQuery(GET_GIFT_CARDS);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  const filteredCards = data.allMyGiftCards.reduce(
    (accumulator, currentCard) => {
      accumulator.push(currentCard);
      return accumulator;
    },
    [],
  );
  return (
    <div>
      <div className="section">
        <Link
          className="button is-pulled-right"
          to={routes.NEW_GIFT_CARD}
        >
          Add new Gift Card
        </Link>
        <button
          className="button is-pulled-left "
          onClick={() => setViewAll(!viewAll)}
        >
          Show {viewAll ? 'Only Active' : 'All'}
        </button>
      </div>
      <br />
      <h3 className="title is-3">Gift Cards Page</h3>
      <div className="card-container">
        {filteredCards
          .sort((a, b) => a.id.localeCompare(b.id))
          .map((card) => {
            if (card.isEdited) {
              return null;
            }
            card.alt = `GiftCard image for ${card.name}`;

            if (!viewAll) {
              if (!card.isActive) {
                return null;
              }
            }
            return (
              <div className="column card-flex is-half" key={card.id}>
                <div className="card box ">
                  {card.isActive ? (
                    <div className="is-pulled-right tag is-success">
                      Active
                    </div>
                  ) : (
                    <div className="is-pulled-right tag">
                      Inactive
                    </div>
                  )}
                  <h3 className="title is-5">{card.name}</h3>
                  <div className="subtitle is-6">
                    Total Sold: {card.countOrders}
                  </div>
                  <GiftCardPreview giftCard={card} />
                  <div className={'level'}>
                    <div
                      className="level-left"
                      style={{ marginTop: '10px' }}
                    >
                      {card.isActive ? (
                        <button
                          className="button is-small"
                          onClick={() => onSubmitDeactivate(card.id)}
                        >
                          Deactivate
                        </button>
                      ) : (
                        <button
                          className="button is-small"
                          onClick={() => onSubmitActivate(card.id)}
                        >
                          Activate
                        </button>
                      )}
                    </div>
                    <div>
                      <Link
                        className="level-right"
                        to={routes.EDIT_CARD.replace(
                          ':uuid',
                          card.id,
                        )}
                      >
                        <button
                          className={'button is-small is-bold '}
                        >
                          Edit
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default GiftCards;
