import React from 'react';
import stripeLogo from '../../assets/images/stripe_logo.png';
import squareLogo from '../../assets/images/square_logo.png';
import authorizeLogo from '../../assets/images/authorize_logo.png';
import StripeConnectButton from '../PaymentGateway/StripeConnectButton';
import { gql, useQuery } from '@apollo/client';
import * as routes from '../../constants/routes';
import { Link } from 'react-router-dom';

export default function SettingsPage(props) {
  const GET_MYSELF = gql`
    query SettingsGetMyself {
      me {
        id
        email
        company {
          paymentGateway {
            id
            isActive
          }
        }
      }
    }
  `;

  const { data } = useQuery(GET_MYSELF);

  if (!data || !data.me) {
    return <div>logged out</div>;
  }

  return (
    <div>
      <div className="columns">
        <div className="column is-4">
          <Link to={routes.COMPANY_SETTINGS} className="box card">
            <span>
              <span role="img" aria-label="Menu Option">
                🔨{' '}
              </span>
              Company Settings
            </span>
          </Link>
        </div>
        <div className="column is-4">
          <Link to={routes.PAYMENT_SETTINGS} className="box card">
            <span>
              <span role="img" aria-label="Menu Option">
                🔨{' '}
              </span>
              Payment Settings
            </span>
          </Link>
        </div>
      </div>

      <div className="columns">
        <div className="column is-4">
          <Link to={routes.PURCHASE_PAGE_SETTINGS} className="box card">
            <span>
              <span role="img" aria-label="Menu Option">
                🔨{' '}
              </span>
              Purchase Page Settings
            </span>
          </Link>
        </div>

        <div className="column is-4">
          <Link to={routes.EMAIL_SETTINGS} className="box card">
            <span>
              <span role="img" aria-label="Menu Option">
                🔨{' '}
              </span>
              Email Template Settings
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
