import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import OrderCard from './OrderCard';

const GET_MY_ORDERS = gql`
  query MyOrders {
    myOrders {
      id
      createdAt
      status
      price
      value
      code
      purchaserEmail
      purchaserName
      recipientName
      recipientEmail
      giftCard {
        backgroundImageUrl
        value
        name
      }
    }
  }
`;

export default function OrderList(props) {
  const { loading, error, data } = useQuery(GET_MY_ORDERS);
  const allOrders = data ? data.myOrders : [];
  const [offset, setOffset] = useState(0);
  const orderData = allOrders.slice(offset, offset + 4);
  const changeOffset = (val) => {
    setOffset(offset + val);
  };
  let backButton =
    offset > 0 ? (
      <a className="pagination-previous" onClick={() => changeOffset(-4)}>Previous Page</a>
    ) : null;
  let nextButton = allOrders[offset + 4] ? (
    <a className="pagination-next" onClick={() => changeOffset(+4)}>Next Page</a>
  ) : null;
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  if (orderData.length == 0) {
    return (
      <div>
        <h3 className="title is-3">Orders</h3>
        <p>
          No orders to display for now. Get selling and your orders
          will appear over here!
        </p>
      </div>
    );
  }
  return (
    <div>
      <h3 className="title is-3">Orders</h3>

      <div>
        <nav class="pagination is-small is-centered" role="navigation" aria-label="pagination">
          {backButton}
          {nextButton}
        </nav>
      </div>
      {orderData.map((order) => (
        <OrderCard order={order} />
      ))}
    </div>
  );
}
