// This is the starting point of the client side of things.
// index.js is a special name because by convention, it's the file which is checked by
// node.js when importing a folder with an import statement.
// import './components/App' will import './components/App/index.js'

// This file is run by the client once it downloads the client source code
// (since it's called index.js and is located at the root of the client folder)
// This file imports all dependencies React, pieces of Apollo (powering the API), and the App itself
// It then defines a render function which configures Apollo with our app's URL, tells Apollo what to in case
// of errors (graphql or network), tells Apollo how to authenticate the user (with a token stored in localStorage),
// configures the Apollo cache and finally initializes the Apollo client.
// It may seem daunting but it's a lot of typical code found on the Internet when reading the doc and fine-tuned based
// on googling around how to solve specific issues we had during development.
// The long story short is: we import React (front-end framework) and Apollo (graphql client)
// configure Apollo in the way we like, and wrap our (also imported) App in an ApolloProvider with all the config above

// Our app is now nicely wrap in a very powerful ApolloProvider which will automate a lot of things for us.
// Thanks to it, Apollo will automatically update React's props with the data fetched by our API. That means we now only
// have to declare which data we need from the back-end, and we can assume it will be fetched by Apollo for us, and updated
// each time new data comes from the server. That way, we can focus on presenting the data in React, rather than on the plumbing
// work you typically have to do without Apollo.
import * as React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, ApolloClient } from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { AUTH_TOKEN_KEY } from './constants/auth';
import App from './components/App';
import { signOut } from './components/SignOut';
import './assets/styles/index.scss';

async function render() {
  // process.env.NODE_ENV is 'production' or 'development' depending on if the app is run locally or not
  // that way, we can dynamically define the few things that will change between the dev and prod environments,
  // like the url Apollo should talk to
  const baseUrl = process.env.NODE_ENV === "production" ? 'https://app.yourzengift.com' : 'http://app.yourzengift.local:3000';
  
  // This graphql endpoint is the only API endpoint exposed by our server
  // See line:
  // server.applyMiddleware({ app, path: "/graphql", tracing: true });
  // in src/main.js (back-end side of things - powered by ApolloServer)
  // Indeed, Apollo is a library that lives in both the client and server side of things as it ties them together
  const httpLink = new HttpLink({ uri: `${baseUrl}/graphql` })

  // The errorLink is how you deal with errors from the API
  // (either because the request is invalid or there are network issues)
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        console.log('GraphQL error below:');
        console.log(JSON.stringify(error, null, 2));
        if (error.extensions.code === 'UNAUTHENTICATED') {
          signOut(client);
        }
      });
    }

    if (networkError) {
      console.log('Network error below:');
      console.log(JSON.stringify(networkError, null, 2));

      if (networkError.statusCode === 401) {
        signOut(client);
      }
    }
  });

  // The authlink is how Apollo deals with authentication
  // It takes the current operation (query or mutation)
  // and just add an Authorization header including a secure token
  // if that token could be found in the localStorage (saved by the web browser)
  // When logged out it will not do anything (the server won't find a token and prevent most actions)
  // When a user logs in, the server trades an email/password for a secure token (see passport dependency)
  // This secure token is saved at the moment of login in localStorage (see SignIn/index.js) and this link
  // includes it in the header of all subsequent requests.
  // On logout, the token is deleted from localStorage (See LogoutButton)
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const token = localStorage.getItem(AUTH_TOKEN_KEY);

      if (token) {
        headers = { ...headers, Authorization: `Bearer ${token}` };
      }

      return { headers };
    });

    return forward(operation);
  });

  // We now have the standard httpLink from Apollo, a link that will deal with errors
  // and a link that will include the session token in the headers of each request when possible
  // We can concatenate those links..
  const link = ApolloLink.from([errorLink, authLink, httpLink]);

  // ..Setup a typical cache (copied from the docs)
  const cache = new InMemoryCache(window.__APOLLO_STATE__);

  // ..Setup some options
  const defaultOptions = {
    connectToDevTools: true,
    credentials: 'include',
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  };

  // ..and tie all of this together to create our ApolloClient
  const client = new ApolloClient({
    link: link,
    cache: cache,
    defaultOptions,
  });

  // Finally we use ApolloProvider to provide this client to our <App /> nested in it.
  // All the magic will now happen within the App (imported from App/index)
  // - with the power of the ApolloClient it now has access to
  // This App will yield all the pages we need (more on that in further comments)
  // And ReactDOM.render will render the page in the element #root of our template
  // /client/public/index.html
  // before being rendered by chrome.
  // This src/index.js file is what ties everything at the highest level (client-side)
  ReactDOM.render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>,
    document.getElementById('root'),
  );
}

// Oh, and let's not forget to call this beast of a function we just defined.
render();
