import React from 'react';
import { Link } from 'react-router-dom';
import GiftCardPreview from './GiftCardPreview';
import * as routes from '../../constants/routes';

export default function GiftCardPreviewLink(props) {
  const purchaseLink = routes.NEW_ORDER_PATH.replace(
    ':companySlug',
    props.companySlug,
  ).replace(':giftCardSlug', props.giftCard.slug);
  return (
    <div>
      <GiftCardPreview
        giftCard={props.giftCard}
        giftCardUrl={purchaseLink}
      />

      <br />
      <Link className="" to={purchaseLink}>
        <button className={'button is-success  is-small is-bold '}>
          Order
        </button>
      </Link>
    </div>
  );
}
