import React, { useEffect } from 'react';
import linkify from '../../lib/linkify';
import instagramIcon from '../../assets/images/instagram-icon-small.png';
import CheckedGatewayShareButton from '../../lib/isGateWayActive';
export default function InstagramSharePrompt(props) {
  const text = 'Give the gift of a clean home! Click the link below:';
  const { shareLink, gateStatus } = props;

  return (
    <div>
      <h2 className="title is-4">
        Don't forget the Instagram crowd love few things more than a
        good deal
      </h2>
      <h4 className="subtitle is-5">
        Share your new gift cards with 500 million Instagrammers now
      </h4>
      <CheckedGatewayShareButton
        el={
          <a
            // href="http://www.linkedin.com/shareArticle?mini=true&url=&title=How%20to%20make%20custom%20linkedin%20share%20button&summary=some%20summary%20if%20you%20want&source=stackoverflow.com"
            className="with-embedded-img button with-shadow is-disabled"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramIcon} alt="Instagram Icon" />
            <span>Share on Instagram</span>
          </a>
        }
        gateStatus={props.gateStatus}
      />
      <small>Coming soon</small>
    </div>
  );
}
