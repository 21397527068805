import React from 'react';
import * as routes from '../../constants/routes';
import { gql, useMutation } from '@apollo/client';

import history from '../../constants/history';

export default function LogoutButton(props) {
  const LOG_OUT = gql`
    mutation LogOut {
      logout
    }
  `;

  function handleClick(ev) {
    mutation();
  }

  const [mutation] = useMutation(LOG_OUT, {
    onCompleted: function () {
      window.localStorage.clear();
      history.push(routes.SIGN_IN);
    },
  });

  return (
    <span
      onClick={handleClick}
      className="button is-small is-secondary"
    >
      Log out
    </span>
  );
}
