import React from 'react';
import * as routes from '../../constants/routes';
import ProfileWidget from '../User/ProfileWidget';
import StorePageLink from '../User/StorePageLink';
import appLogo from '../../assets/images/zengift-logotype.png';
import { Link } from 'react-router-dom';

export default function MainLayout(props) {
  return (
    <header className="with-shadow header">
      <Link to={routes.DASHBOARD}>
        <img src={appLogo} alt="Your Logo" />
      </Link>
      <Link to={routes.QUICK_REDEEM}>Quick Redeem</Link>

      <StorePageLink />
      <ProfileWidget />
    </header>
  );
}
