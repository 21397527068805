// Converts $75.23 -> 7523
export default (valueString) => {
  if (!valueString) { return null; }

  const valueFloat = Number(valueString.replace(",", ".").replace(/[^0-9.]/g, ""));
  if (isNaN(valueFloat)) {
    return null;
  } else {
    return Math.round(valueFloat * 100);
  }
};
