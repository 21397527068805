import React from 'react';
import { useForm } from 'react-hook-form';
import { gql, useMutation, RenderPromises } from '@apollo/client';
import * as routes from '../../constants/routes';
import history from '../../constants/history';
import linkify from '../../lib/linkify';
import valueStringToCents from '../../lib/valueStringToCents';
import GiftCardPreview from './GiftCardPreview';
import { ToastContainer } from 'react-toastify';
import { successToast, errorToast } from '../../constants/toasts';

export default function GiftCardForm(props) {
  const CREATE_GIFTCARD = gql`
    mutation CreateGiftCard($giftCardInput: GiftCardInput!) {
      createGiftCard(giftCardInput: $giftCardInput) {
        id
      }
    }
  `;

  const [mutation] = useMutation(CREATE_GIFTCARD, {
    onError(res) {
      console.log('Create giftcard Err:', res);
    },

    refetchQueries: ['AllMyGiftCards'],
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    errors,
  } = useForm({
    criteriaMode: 'all',
    defaultValues: {
      backgroundImageUrl: linkify(
        `media/gift-card-default-background-1.png`,
      ),
    },
  });

  watch(['name', 'description', 'value']);

  const onSubmit = async (values) => {
    if (Number(values.price) > Number(values.value)) {
      errorToast(
        `The price (${values.price}) cannot be bigger than the value(${values.value}) of the card `,
      );
    } else {
      await mutation({
        variables: {
          giftCardInput: {
            name: values.name,
            price: valueStringToCents(values.price),
            value: valueStringToCents(values.value),
            description: values.description,
            additionalInfoUrl: values.additionalInfoUrl,
            backgroundImageUrl: values.backgroundImageUrl,
          },
        },
      })
        .then((res) => {
          successToast('Your card has been made!');
          history.push(routes.GIFT_CARDS);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function handleGiftCardClick(event) {
    const currentSelected = document.querySelector('.selectedCard');
    if (currentSelected)
      currentSelected.classList.remove('selectedCard');
    event.currentTarget.parentNode.classList.add('selectedCard');
    setValue([
      { backgroundImageUrl: event.currentTarget.children[0].src },
    ]);
  }

  let cardPreview = Object.assign({}, getValues());
  cardPreview.value = valueStringToCents(cardPreview.value);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ToastContainer />
      <h3 className="title is-3">Create A New Gift Card</h3>
      <div className="columns">
        <div className="column is-4">
          <p>
            You're minutes away from the gift of revenue. More options
            for your clients is good but don't offer too many!
          </p>
          <br />
          <p>
            Please note that you will not be able to edit this card
            once this is made
          </p>
        </div>
        <div className="column is-8">
          <div className="field">
            <label className="label">Card Name</label>
            <input
              name="name"
              className="input"
              type="text"
              placeholder="Spring Clean Gift Card"
              minLength="5"
              maxLength="25"
              ref={register({
                required: 'Required',
                minLength: {
                  value: 5,
                  message:
                    'A giftcard name cannot be less than 5 characters',
                },
                maxLength: {
                  value: 25,
                  message:
                    'A giftcard name cannot be more than 25 characters',
                },
              })}
            />
            {errors.name && (
              <p className={'has-text-danger'}>
                {errors.name.message}
              </p>
            )}
          </div>
          <div className="field">
            <label className="label">
              Gift Card Price (amount charged)
            </label>

            <input
              name="price"
              className="input column is-2"
              type="number"
              min="5"
              max="1000"
              placeholder="$75"
              ref={register({
                required: 'Required',
                pattern: {
                  value: /[\0-9$]*/g,
                  message: 'Whole Numbers Only',
                },
                max: {
                  value: 1000,
                  message: 'A giftcard cannot be more that $1000',
                },
                min: {
                  value: 5,
                  message: 'A giftcard cannot be less than $5',
                },
              })}
            />

            {errors.price && (
              <p className={'has-text-danger'}>
                {errors.price.message}
              </p>
            )}
          </div>
          <div className="field">
            <label className="label">Gift Card Value</label>
            <div>
              <input
                name="value"
                className="input column is-2"
                type="number"
                min="5"
                max="1000"
                placeholder="$100"
                ref={register({
                  required: 'Required',
                  pattern: {
                    value: /[\0-9$]*/g,
                    message: 'Whole Numbers Only',
                  },
                  max: {
                    value: 1000,
                    message: 'A giftcard cannot be more that $1000',
                  },
                  min: {
                    value: 5,
                    message: 'A giftcard cannot be less than $5',
                  },
                })}
              />
              {errors.value && (
                <p className={'has-text-danger'}>
                  {errors.value.message}
                </p>
              )}
            </div>
          </div>
          <div className="field">
            <label className="label">Description</label>
            <input
              name="description"
              className="input column is-6"
              type="text"
              placeholder="Gift card for the special spring offer"
              minLength="5"
              maxLength="35"
              ref={register({
                required: 'Required',
                minLength: {
                  value: 5,
                  message:
                    'A giftcard description cannot be less than 5 characters',
                },
                maxLength: {
                  value: 35,
                  message:
                    'A giftcard description cannot be more than 35 characters',
                },
              })}
            />
            {errors.description && (
              <p className={'has-text-danger'}>
                {errors.description.message}
              </p>
            )}
          </div>
          <div className="field">
            <label className="label">Additional Info URL</label>
            <input
              name="additionalInfoUrl"
              className="input"
              type="text"
              placeholder="http://mymaidservice.com"
              ref={register({
                pattern: {
                  value:
                    '_(?i)\b((?:https?|ftps?|mailto|wwwd{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}/)(?:[^s()<>]+|(([^s()<>]+|(([^s()<>]+)))*))+(?:(([^s()<>]+|(([^s()<>]+)))*)|[^s`!()[]{};:\'".,<>?«»“”‘’]))_iuS',
                  message: 'Must be a valid url',
                },
              })}
            />
          </div>
          {errors.additionalInfoUrl && (
            <p className={'has-text-danger'}>
              {errors.additionalInfoUrl.message}
            </p>
          )}
        </div>
      </div>
      <div className="columns">
        <div className="column is-5">
          <h4 className="title is-5">Pick a card design</h4>
          <div className="columns">
            <div className="column is-6">
              <figure
                className="image is-3by2 with-shadow"
                onClick={handleGiftCardClick}
              >
                <img
                  src={linkify(
                    `media/gift-card-default-background-1.png`,
                  )}
                  alt="GiftCard Option 1"
                />
              </figure>
            </div>
            <div className="column is-6">
              <figure
                className="image is-3by2 with-shadow"
                onClick={handleGiftCardClick}
              >
                <img
                  src={linkify(
                    `media/gift-card-default-background-2.png`,
                  )}
                  alt="GiftCard Option 1"
                />
              </figure>
            </div>
          </div>
          <div className="columns">
            <div className="column is-6">
              <figure
                className="image is-3by2 with-shadow"
                onClick={handleGiftCardClick}
              >
                <img
                  src={linkify(
                    `media/gift-card-default-background-3.png`,
                  )}
                  alt="GiftCard Option 1"
                />
              </figure>
            </div>
            <div className="column is-6">
              <figure
                className="image is-3by2 with-shadow"
                onClick={handleGiftCardClick}
              >
                <img
                  src={linkify(
                    `media/gift-card-default-background-4.png`,
                  )}
                  alt="GiftCard Option 1"
                />
              </figure>
            </div>
          </div>
        </div>

        <div className="column is-7" style={{ marginLeft: '30px' }}>
          <h4 className="title is-5">Preview of your gift card</h4>
          <GiftCardPreview giftCard={cardPreview} />
          <input
            name="backgroundImageUrl"
            type="hidden"
            ref={register({ required: 'Required' })}
          />
          {errors.backgroundImageUrl && (
            <p className={'has-text-danger'}>
              {errors.backgroundImageUrl.message}
            </p>
          )}
        </div>
      </div>
      <button
        type="submit"
        className="button is-primary is-pulled-right"
      >
        Save
      </button>
      <br />
      <br />
      <br />
      <br />
    </form>
  );
}
