export const SIGN_UP = '/sign-up';
export const SIGN_IN = '/login';
export const ONBOARDING_STEP_ONE = '/welcome';
export const ONBOARDING_STEP_TWO = '/get-paid';
export const FACEBOOK_SHARE = '/facebook-share';
export const LINKEDIN_SHARE = '/linkedin-share';
export const TWITTER_SHARE = '/twitter-share';
export const INSTAGRAM_SHARE = '/instagram-share';
export const URL_SHARE = '/your-link';
export const DASHBOARD = '/dashboard';
export const GIFT_CARDS = '/gift-cards';
export const NEW_GIFT_CARD = '/gift-cards/new';
export const SHARE_LINKS = '/share-links';
export const FACEBOOK_PROMOTION = '/facebook-promotion';
export const REPORTS = '/reports';
export const SETTINGS = '/settings';
export const PURCHASE_PAGE_LIST = '/from/:slug';
export const TEST_STORE_PAGE = '/test-store-page';
export const TEST_ORDER_PAGE = '/test-order-page';
export const QUICK_REDEEM = '/quick-redeem';
export const ORDER_LIST = '/my-orders';
export const PURCHASE_PAGE_SETTINGS = '/settings/purchase-page';
export const EMAIL_SETTINGS = '/settings/email';
export const PAYMENT_SETTINGS = '/settings/payment';
export const COMPANY_SETTINGS = '/settings/company';
export const ORDER_PAYMENT_PAGE = '/orders/:uuid/pay';
export const ORDER = '/orders/:uuid';
export const NEW_ORDER_PATH = '/:companySlug/:giftCardSlug';
export const STORE_PAGE = '/:companySlug';
export const EDIT_CARD = '/edit/:uuid';
export const PASSWORD_RESET = '/password-reset';
export const NEW_PASSWORD = '/new-password/:token';
export const PRIVACY_POLICY = '/privacy-policy';
