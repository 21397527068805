import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import successIcon from '../../assets/images/checkmark_icon.png';
import appLogo from '../../assets/images/zengift-logo.png';
import { AUTH_TOKEN_KEY } from '../../constants/auth';

import * as routes from '../../constants/routes';

import history from '../../constants/history';

export default function PasswordRecoveryRequestPage(props) {
  const RESET_PASSWORD_REQ = gql`
    mutation ResetPassword($recoveryReqInput: RecoveryReqInput!) {
      recoveryRequest(recoveryReqInput: $recoveryReqInput)
    }
  `;

  const [displayError, setDisplayError] = useState('');
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [mutation] = useMutation(RESET_PASSWORD_REQ, {
    onCompleted(data) {
      setRequestSuccess(data.recoveryRequest);
    },
    onError(res) {
      setDisplayError(res);
    },
  });

  const { handleSubmit, register, errors } = useForm();
  const onSubmit = (values) => {
    mutation({
      variables: {
        recoveryReqInput: {
          email: values.email,
        },
      },
    });
  };
  if (requestSuccess) {
    return (
      <div
        style={{
          textAlign: 'center',
          padding: '30px',
          background: 'white',
        }}
      >
        <img width="120" src={successIcon} alt="Green Checkmark" />
        <h3 className="title is-4">Check Your Email to continue</h3>
        <p style={{ marginTop: '-20px' }}>
          The reset password link will be sent to your email within
          the next few minutes. <br />
          Check your inbox and click on the link to proceed with
          resetting your password.
        </p>
        <small
          style={{
            opacity: 0.6,
            marginTop: '30px',
            display: 'block',
            color: '#888',
          }}
        >
          You can close this window now
        </small>
      </div>
    );
  }
  return (
    <div className="sign-in-card">
      <div className="logo-container with-shadow">
        <img src={appLogo} alt="App Logo" />
      </div>
      <h3 className="title is-2">Reset Password</h3>
      <h4 className="subtitle">
        <Link to={routes.SIGN_IN}>Or go back to login</Link>
      </h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>Please provide your account email</label>
          <input
            name="email"
            placeholder="your.email@example.com"
            ref={register({
              required: 'Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'invalid email address',
              },
            })}
          />
          {errors.email && errors.email.message}
        </div>
        <div>
          {displayError && displayError.message}
          <button className="button is-primary" type="submit">
            Reset Password
          </button>
        </div>
      </form>
    </div>
  );
}
