import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
import centsToValueString from '../../lib/centsToValueString';

export default function OrdersTable(props) {
  const sortedOrders = props.orders
    .sort((a, b) => b.createdAt - a.createdAt)
    .slice(0, 10);

  return (
    <div style={{ paddingBottom: 150 }}>
      <table className="table is-striped is-narrow is-hoverable is-fullwidth ">
        <thead>
          <tr>
            <th>Date</th>
            <th>Code</th>
            <th>Item</th>
            <th>Price</th>
            <th>Value</th>
            <th>Buyer</th>
            <th>Recipient</th>
            <th>Delivery</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.orders.length > 0 ? (
            sortedOrders.map((order) => {
              return (
                <tr key={order.id.toString()}>
                  <td>{moment(order.createdAt, "x").format("MMM DD 'YY")}</td>
                  <td>{order.code.slice(0, 4)}</td>
                  <td>{order.giftCard.name}</td>
                  <td>{centsToValueString(order.value)}</td>
                  <td>{centsToValueString(order.price)}</td>
                  <td>{order.purchaserEmail}</td>
                  <td>{order.recipientEmail}</td>
                  <td>Sent</td>
                  <td>
                    <Link
                      to={routes.ORDER.replace(':uuid', order.id)}
                      className="button is-small"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              );
            })
          ) : (
            <div>
              {' '}
              <br />
              No orders to display. Start selling!
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
}
