import React from 'react';
import { useForm } from 'react-hook-form';
import { gql, useMutation, useQuery } from '@apollo/client';
import * as routes from '../../constants/routes';
import history from '../../constants/history';
import { savedToast, errorToast } from '../../constants/toasts';
import { ToastContainer } from 'react-toastify';

export default function EmailTemplateSettings(props) {
  const UPDATE_EMAIL_TEMPLATES = gql`
    mutation UpdateEmailSettings(
      $giftCardEmailTemplateInput: GiftCardEmailTemplateInput!
      $receiptEmailTemplateInput: ReceiptEmailTemplateInput!
    ) {
      updateMyReceiptEmailTemplate(
        receiptEmailTemplateInput: $receiptEmailTemplateInput
      ) {
        id
      }
      updateMyGiftCardEmailTemplate(
        giftCardEmailTemplateInput: $giftCardEmailTemplateInput
      ) {
        id
      }
    }
  `;

  const [updateEmailSettingsMutation] = useMutation(
    UPDATE_EMAIL_TEMPLATES,
    {
      onError(res) {
        console.log('Update Email Template Err', res);
      },
      refetchQueries: ['GetEmailTemplates'],
    },
  );

  const setting = props.setting;
  const giftCardEmailTemplateData =
    props.setting.giftCardEmailTemplate;
  const receiptEmailTemplateData = props.setting.receiptEmailTemplate;

  const { handleSubmit, register } = useForm({
    defaultValues: {
      giftCardFromName: giftCardEmailTemplateData.fromName,
      giftCardFromEmail: giftCardEmailTemplateData.fromEmail,
      giftCardSubject: giftCardEmailTemplateData.subject,
      giftCardTitle: giftCardEmailTemplateData.title,
      giftCardBody: giftCardEmailTemplateData.body,
      cta: giftCardEmailTemplateData.cta,
      ctaLink: giftCardEmailTemplateData.ctaLink,
      giftCardFooter: giftCardEmailTemplateData.emailFooter,
      receiptFromName: receiptEmailTemplateData.fromName,
      receiptFromEmail: receiptEmailTemplateData.fromEmail,
      receiptSubject: receiptEmailTemplateData.subject,
      receiptTitle: receiptEmailTemplateData.title,
      receiptBody: receiptEmailTemplateData.body,
      receiptEmailFooter: receiptEmailTemplateData.emailFooter,
    },
  });

  const onSubmit = (values) => {
    updateEmailSettingsMutation({
      variables: {
        giftCardEmailTemplateInput: {
          fromEmail: values.giftCardFromEmail,
          fromName: values.giftCardFromName,
          subject: values.giftCardSubject,
          title: values.giftCardTitle,
          body: values.giftCardBody,
          cta: values.cta,
          ctaLink: values.ctaLink,
          emailFooter: values.giftCardFooter,
          settingId: giftCardEmailTemplateData.settingId,
        },
        receiptEmailTemplateInput: {
          fromEmail: values.receiptFromEmail,
          fromName: values.receiptFromName,
          subject: values.receiptSubject,
          title: values.receiptTitle,
          body: values.receiptBody,
          emailFooter: values.receiptEmailFooter,
          settingId: receiptEmailTemplateData.settingId,
        },
      },
    })
      .then(savedToast('👍 Settings Saved!'))
      .then(history.push(routes.EMAIL_SETTINGS))
      .catch((err) => {
        errorToast(err);
        console.log(err);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ToastContainer />
      <h3 className="title is-3">Email Template Settings</h3>
      <div className="columns">
        <div className="column is-4">
          <p>
            Customize the emails that go out to your customers and
            make it your own! <br />
            <br />
            We strongly suggest you add a CTA (call to action) button
            so that when the giftcard recipient receives an email they
            can go and directly book an appointment with you.
          </p>
        </div>
        <div className="column is-8">
          <h2>Update your GiftCard Email Settings</h2>
          <div className="field">
            <label className="label">From Name</label>
            <input
              name="giftCardFromName"
              className="input"
              type="text"
              placeholder="Courtney Wisely"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">From Email</label>
            <input
              name="giftCardFromEmail"
              className="input"
              type="text"
              placeholder="courtney.wisely@zenmaid.com"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Subject</label>
            <input
              name="giftCardSubject"
              className="input"
              type="text"
              placeholder="Is it your birthday? Someone just offered you a gift card :)"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Title</label>
            <input
              name="giftCardTitle"
              className="input"
              type="text"
              placeholder="Here's to you! Your Gift Card is ready!"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Body</label>
            <textarea
              name="giftCardBody"
              className="input"
              type="text"
              placeholder="In order to redeem your gift card, click on the link below:"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Button Text</label>
            <input
              name="cta"
              className="input"
              type="text"
              placeholder="Redeem my gift"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Button Link</label>
            <input
              name="ctaLink"
              className="input"
              type="text"
              placeholder="www.cleaningsite.com/book-now"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Footer</label>
            <input
              name="giftCardFooter"
              className="input"
              type="text"
              placeholder="Thank you very much - we hope to serve you soon!"
              ref={register({})}
            />
          </div>
          <br />
          <h2>Update your Receipt Email Settings</h2>
          <div className="field">
            <label className="label">From Name</label>
            <input
              name="receiptFromName"
              className="input"
              type="text"
              placeholder="Courtney Wisely"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">From Email</label>
            <input
              name="receiptFromEmail"
              className="input"
              type="text"
              placeholder="courtney.wisely@zenmaid.com"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Subject</label>
            <input
              name="receiptSubject"
              className="input"
              type="text"
              placeholder="Thanks a lot for your order! Here is your receipt."
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Title</label>
            <input
              name="receiptTitle"
              className="input"
              type="text"
              placeholder="Thanks a lot for buying a gift card"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Body</label>
            <textarea
              name="receiptBody"
              className="input"
              type="text"
              placeholder="Here are all the details related to your order:&#013;Date: {{order_date}}&#013;From: {{purchaser_name}}&#013;To: {{recipient_name}}&#013;Price: {{order_price}}&#013;Card Value: {{order_value}}&#013;"
              ref={register({})}
            />
          </div>
          <div className="field">
            <label className="label">Footer</label>
            <input
              name="receiptEmailFooter"
              className="input"
              type="text"
              placeholder="We hope you will buy more gift cards from us very soon!"
              ref={register({})}
            />
          </div>
          <button type="submit" className="button is-primary">
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
