import React from 'react';
import * as routes from '../constants/routes';
import { Link } from 'react-router-dom';

export default function CheckedGatewayShareButton({
  el,
  gateStatus,
}) {
  return gateStatus ? (
    el
  ) : (
    <Link
      to={routes.ONBOARDING_STEP_TWO}
      className="button with-shadow is-success"
    >
      {' '}
      <strong>
        Please connect your account to Stripe to share
      </strong>{' '}
    </Link>
  );
}
