import React from 'react';
import ConnectStripeMotivator from './ConnectStripeMotivator';
import AddGiftCardMotivator from './AddGiftCardMotivator';

export default function OnboardingProgressMotivator(props) {
  return (
    <div className="is-clearfix with-shadow purchase-page">
      <h3 className="title is-5">
        Your store cannot be listed just yet.
      </h3>
      <p className="subtitle is-6">
        Here are a couple steps that need your attention:
      </p>
      <div className="columns">
        <div className="column is-6">
          <ConnectStripeMotivator stripeActive={props.stripeActive} />
        </div>
        <div className="column is-6">
          <AddGiftCardMotivator anyCard={props.anyCard} />
        </div>
      </div>
    </div>
  );
}
