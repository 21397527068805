import React from 'react';
import { gql, useQuery } from '@apollo/client';
import linkify from '../../lib/linkify';
import FacebookSharePrompt from '../ShareLinks/FacebookSharePrompt';
import LinkedInSharePrompt from '../ShareLinks/LinkedInSharePrompt';
import TwitterSharePrompt from '../ShareLinks/TwitterSharePrompt';
import InstagramSharePrompt from '../ShareLinks/InstagramSharePrompt';
import { GET_MY_SHARELINK } from '../../queries/currentUser';

const ShareLinks = ({ session }) => {
  const { loading, error, data } = useQuery(GET_MY_SHARELINK);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  const purchasePage = data.me.company.purchasePage;

  const shareLink = linkify(purchasePage.shareLink);

  return (
    <div className="share-links-collection">
      <h2 className="title is-3">
        The more you share, the more you sell.
      </h2>
      <div className="columns">
        <div className="column is-12" key={purchasePage.id}>
          <div className="card box" style={{ minHeight: '140px' }}>
            <h3 className="title is-4">Your ShareLink</h3>
            <div>
              <a
                href={shareLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {shareLink}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="card box">
            <TwitterSharePrompt
              shareLink={shareLink}
              gateStatus={data.me.company.paymentGateway.isActive}
            />
          </div>
        </div>
        <div className="column">
          <div className="card box">
            <FacebookSharePrompt
              shareLink={shareLink}
              gateStatus={data.me.company.paymentGateway.isActive}
            />
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="card box">
            <LinkedInSharePrompt
              shareLink={shareLink}
              gateStatus={data.me.company.paymentGateway.isActive}
            />
          </div>
        </div>
        <div className="column">
          <div className="card box">
            <InstagramSharePrompt
              shareLink={shareLink}
              gateStatus={data.me.company.paymentGateway.isActive}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareLinks;
