import React from 'react';

const LoginLayout = (props) => (
  <div>
    <main className="layout-body">
      <div
        className="layout-content"
        style={{ backgroundColor: '#1E3770' }}
      >
        {props.children}
      </div>
    </main>
  </div>
);

export default LoginLayout;
