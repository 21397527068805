import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import * as routes from '../../constants/routes';
import history from '../../constants/history';
import { savedToast, errorToast } from '../../constants/toasts';
import { ToastContainer } from 'react-toastify';
import Uploader from '../Uploader/index';
import emptyLogo from '../../assets/images/no-image-found.png';

export default function CompanySettingsForm(props) {
  const UPDATE_MY_COMPANY = gql`
    mutation UpdateMyCompany($companyInput: CompanyInput!) {
      updateMyCompany(companyInput: $companyInput) {
        id
      }
    }
  `;

  const [updateMyCompanyMutation] = useMutation(UPDATE_MY_COMPANY, {
    onError(res) {
      console.log('Update Company Err', res);
    },
  });

  const company = props.company;

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      name: company.name,
      phone: company.phone,
      email: company.email,
    },
  });

  const onSubmit = (values) => {
    // uploadFile(values.logo[0], company.id);
    updateMyCompanyMutation({
      variables: {
        companyInput: {
          name: values.name,
          phone: values.phone,
          email: values.email,
          logo: values.logo,
        },
      },
    })
      .then(savedToast('👍 Company Information Saved!'))
      .then(history.push(routes.COMPANY_SETTINGS))

      .catch((err) => {
        errorToast(err);
        console.log(err);
      });
  };
  const currentImgRef = useRef();
  const logoInputRef = useRef();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ToastContainer />
      <h3 className="title is-3">Update Company Info</h3>
      <div className="columns">
        <div className="column is-4">
          <p>Use this form to update your company's information</p>
        </div>
        <div className="column is-8">
          <div className="field">
            <label className="label">Company Name</label>
            <input
              name="name"
              className="input"
              type="text"
              defaultValue={company.name}
              placeholder="Maids In Blue"
              ref={register({
                minLength: {
                  value: 5,
                  message:
                    'Your company name must be at least 5 characters long',
                },
                maxLength: {
                  value: 50,
                  message:
                    'Your company name must be at most 50 characters long',
                },
              })}
            />
            {errors.name && (
              <p className={'has-text-danger'}>
                {errors.name.message}
              </p>
            )}
          </div>
          <div className="field">
            <label className="label">Company Phone Number</label>
            <input
              name="phone"
              className="input"
              type="text"
              defaultValue={company.phone}
              placeholder="+1 722 555 1234"
              minLength="10"
              ref={register({
                minLength: {
                  value: 10,
                  message:
                    'Your phone number must be at least 10 digits long',
                },
              })}
            />
            {errors.phone && (
              <p className={'has-text-danger'}>
                {errors.phone.message}
              </p>
            )}
          </div>
          <div className="field">
            <label className="label">Company Email</label>
            <input
              name="email"
              className="input"
              type="email"
              defaultValue={company.email}
              placeholder="maids.in.blue@example.com"
              ref={register({})}
            />
          </div>

          <div className=" container">
            <label className="label">Company Logo</label>
            <div className="columns field">
              <div className=" column is-one-third">
                <img
                  src={company.logo || emptyLogo}
                  ref={currentImgRef}
                  alt="Current Avatar"
                />
                <input
                  name="logo"
                  className="input"
                  type="hidden"
                  ref={(e) => {
                    register(e);
                    logoInputRef.current = e;
                  }}
                />
              </div>
              <div className="column is-5">
                <Uploader
                  uploadObject="logo"
                  companyId={company.id}
                  currentImgRef={currentImgRef}
                  imageInputRef={logoInputRef}
                  subfolders={'companies/logo'}
                />
              </div>
            </div>
          </div>
          <br />

          <button type="submit" className="button is-primary">
            Save
          </button>
        </div>
        {console.log(currentImgRef)}
      </div>
    </form>
  );
}
