import React from 'react';
import appLogo from '../../assets/images/zengift-logotype.png';
import '../../assets/styles/footer.scss';

export default function BrandingFooter() {
  return (
    <div className="columns ">
      <div className="is-centered column is-3 is-offset-one-third has-text-centered">
        <span>
          <img src={appLogo} width="100%" className="image"></img>
        </span>

        <span>Powered by ZenGift</span>
      </div>
    </div>
  );
}
