import React, { useEffect } from 'react';
import linkify from '../../lib/linkify';
import linkedinIn from '../../assets/images/linkedin-in-small.png';
import CheckedGatewayShareButton from '../../lib/isGateWayActive';
export default function LinkedInSharePrompt(props) {
  const articleTitle = 'Give the gift of a clean home!';
  const articleSummary =
    'Support your local cleaning service by offering a clean to your loved ones!';
  const { shareLink, gateStatus } = props;

  return (
    <div>
      <h2 className="title is-4">
        Next up, your professional network wants to support you and
        your business
      </h2>
      <h4 className="subtitle is-5">
        Gift cards are the perfect present for coworkers, young
        professionals and busy executives
      </h4>
      <CheckedGatewayShareButton
        el={
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareLink}&title=${articleTitle}&summary=${articleSummary}&source=${shareLink}`}
            className="with-embedded-img button with-shadow"
            target="_blank rel=noopener"
          >
            <img src={linkedinIn} alt="LinkedIn" />
            <span>Share on LinkedIn</span>
          </a>
        }
        gateStatus={gateStatus}
      />
    </div>
  );
}
