import React, { useEffect, useRef, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { successToast, errorToast } from '../../constants/toasts';
import { ToastContainer } from 'react-toastify';
import history from '../../constants/history';
import * as routes from '../../constants/routes';
import substr from '../../lib/substr';

export default function PurchasePage(props) {
  const CREATE_REDEMPTION = gql`
    mutation createRedemption($redemptionInput: RedemptionInput!) {
      createRedemption(redemptionInput: $redemptionInput) {
        id
        order {
          id
        }
      }
    }
  `;

  const { handleSubmit, register, setValue, errors } = useForm();
  const [lastKey, setlastKey] = useState();
  const [lastClick, setlastClick] = useState();
  const [redeemOrder] = useMutation(CREATE_REDEMPTION);
  const onSubmit = async (values) => {
    const {
      code1,
      code2,
      code3,
      code4,
      redeemerName,
      notes,
    } = values;

    const code = code1.concat(code2, code3, code4);

    await redeemOrder({
      variables: {
        redemptionInput: {
          code,
          redeemerName,
          notes,
        },
      },
    })
      .then((res) => {
        successToast('Redemption Successfully Made!');
        setTimeout(function () {
          history.push(
            routes.ORDER.replace(
              ':uuid',
              res.data.createRedemption.order.id,
            ),
          );
        }, 3000);

        return res;
      })
      .catch((err) => errorToast(err));
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const queryCode = query.get('code');

    if (queryCode && queryCode.length == 16) {
      const code1 = queryCode.slice(0, 4);
      const code2 = queryCode.slice(4, 8);
      const code3 = queryCode.slice(8, 12);
      const code4 = queryCode.slice(12, 16);
      window.history.replaceState(
        {},
        document.title,
        '/quick-redeem',
      );

      setValue([
        { code1: code1 },
        { code2: code2 },
        { code3: code3 },
        { code4: code4 },
      ]);
    }
  });

  ['paste'].forEach(function (event) {
    document.addEventListener(event, function (e) {
      var data = e.clipboardData.getData('Text');

      e.preventDefault();
      switch (Math.floor(data / 4)) {
        case 0:
          code1Ref.current.value = substr(data, 0, 4);
          break;
        case 1:
          code1Ref.current.value = substr(data, 0, 4);
          code2Ref.current.value = substr(data, 4, 8);
          break;
        case 2:
          code1Ref.current.value = substr(data, 0, 4);
          code2Ref.current.value = substr(data, 4, 8);
          code3Ref.current.value = substr(data, 8, 12);
          break;
        case 3:
          code1Ref.current.value = substr(data, 0, 4);
          code2Ref.current.value = substr(data, 4, 8);
          code3Ref.current.value = substr(data, 8, 12);
          code4Ref.current.value = substr(data, 12, 16);
          break;

        default:
          code1Ref.current.value = substr(data, 0, 4);
          code2Ref.current.value = substr(data, 4, 8);
          code3Ref.current.value = substr(data, 8, 12);
          code4Ref.current.value = substr(data, 12, 16);
          break;
      }
    });
  });
  const keyCheck = function (e) {
    setlastKey(e.key);
  };

  window.document.addEventListener('keydown', (e) => keyCheck(e), {
    once: true,
  });
  // window.document.removeEventListener('keydown', keyCheck);
  const autoTab = (e) => {
    const codeTotal = [
      code1Ref.current.value.length,
      code2Ref.current.value.length,
      code3Ref.current.value.length,
      code4Ref.current.value.length,
    ].reduce((prev, cur) => {
      return prev + cur;
    });
    if (lastKey === 'Backspace' && codeTotal % 4 === 0) {
      switch (Math.floor(codeTotal / 4)) {
        case 0:
          code1Ref.current.focus();
          break;
        case 1:
          code1Ref.current.focus();
          break;
        case 2:
          code2Ref.current.focus();
          break;
        case 3:
          code3Ref.current.focus();
          break;
        default:
          break;
      }
    }
    if (
      lastKey != 'Backspace' &&
      (lastClick === 1 || lastClick === undefined || codeTotal > 5)
    ) {
      switch (Math.floor(codeTotal / 4)) {
        case 0:
          code1Ref.current.focus();
          break;
        case 1:
          code2Ref.current.focus();
          break;
        case 2:
          code3Ref.current.focus();
          break;
        case 3:
          code4Ref.current.focus();
          break;
        default:
          code4Ref.current.focus();
          break;
      }
    }
  };
  const code1Ref = useRef();
  const code2Ref = useRef();
  const code3Ref = useRef();
  const code4Ref = useRef();
  return (
    <div>
      <h2 className="title is-4">Quick Redeem</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ToastContainer />
        <div className="columns">
          <div className="column is-8">
            <div className="field">
              <label className="label">
                Please enter the 16 digit code.
              </label>

              <p className="control">
                <input
                  name="code1"
                  type="number"
                  onClick={(e) => setlastClick(1)}
                  onChange={(e) => autoTab(e)}
                  className="input mr-m card"
                  style={{ width: '70px' }}
                  placeholder="####"
                  maxLength="4"
                  autoFocus
                  ref={(e) => {
                    register(e, {
                      required: 'Required',
                      pattern: {
                        value: /^[0-9]/i,
                        message: 'Numbers only',
                      },
                      minLength: { value: 4 },
                      maxLength: {
                        value: 4,
                      },
                    });

                    code1Ref.current = e;
                  }}
                />
                <input
                  name="code2"
                  className="input mr-m card"
                  type="number"
                  onChange={(e) => autoTab(e)}
                  onClick={(e) => setlastClick(2)}
                  style={{ width: '70px' }}
                  placeholder="####"
                  maxLength="4"
                  ref={(e) => {
                    register(e, {
                      required: 'Required',
                      pattern: {
                        value: /^[0-9]/i,
                        message: 'Numbers only',
                      },
                      minLength: { value: 4 },
                      maxLength: {
                        value: 4,
                      },
                    });

                    code2Ref.current = e;
                  }}
                />
                <input
                  name="code3"
                  className="input mr-m card"
                  type="number"
                  onClick={(e) => setlastClick(3)}
                  onChange={(e) => autoTab(e)}
                  style={{ width: '70px' }}
                  placeholder="####"
                  maxLength="4"
                  ref={(e) => {
                    register(e, {
                      required: 'Required',
                      pattern: {
                        value: /^[0-9]/i,
                        message: 'Numbers only',
                      },
                      minLength: { value: 4 },
                      maxLength: {
                        value: 4,
                      },
                    });

                    code3Ref.current = e;
                  }}
                />
                <input
                  name="code4"
                  className="input mr-m card"
                  type="number"
                  onClick={(e) => setlastClick(4)}
                  onChange={(e) => autoTab(e)}
                  style={{ width: '70px' }}
                  placeholder="####"
                  maxLength="4"
                  ref={(e) => {
                    register(e, {
                      required: 'Required',
                      pattern: {
                        value: /^[0-9]/i,
                        message: 'Numbers only',
                      },
                      minLength: { value: 4 },
                      maxLength: {
                        value: 4,
                      },
                    });

                    code4Ref.current = e;
                  }}
                />
              </p>
              {errors.code1 && (
                <p className={'has-text-danger'}>
                  {errors.code1.message}
                </p>
              )}
              {errors.code2 && (
                <p className={'has-text-danger'}>
                  {errors.code2.message}
                </p>
              )}
              {errors.code3 && (
                <p className={'has-text-danger'}>
                  {errors.code3.message}
                </p>
              )}
              {errors.code4 && (
                <p className={'has-text-danger'}>
                  {errors.code4.message}
                </p>
              )}
            </div>

            <div className="field">
              <label className="label">Redeemed by (Optional)</label>
              <input
                name="redeemerName"
                className="input"
                type="text"
                placeholder="Name"
                ref={register({})}
              />
            </div>

            <div className="field">
              <label className="label">Notes (Optional)</label>
              <textarea
                name="notes"
                className="textarea"
                type="text"
                placeholder="Write notes about this order here"
                ref={register({})}
              />
            </div>

            <button
              className="mt-m button is-primary is-pulled-right"
              type="submit"
            >
              Redeem Gift Card
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
