import React, { useEffect } from 'react';
import linkify from '../../lib/linkify';
import twitterBird from '../../assets/images/twitter-bird-small.png';
import CheckedGatewayShareButton from '../../lib/isGateWayActive';
export default function TwitterSharePrompt(props) {
  const text = 'Give the gift of a clean home! Click the link below:';
  const { shareLink, gateStatus } = props;

  return (
    <div>
      <h2 className="title is-4">
        Lots of folks are active on the Twitterverse
      </h2>
      <h4 className="subtitle is-5">
        Share your new gift cards with the hashtag{' '}
        <span className="is-bold text-gradient-light-turquoise">
          #yourzengift
        </span>{' '}
        and our team may even retweet it for you!
      </h4>
      <CheckedGatewayShareButton
        el={
          <a
            href={`http://twitter.com/share?text=${text}&url=${shareLink}&hashtags=yourzengift`}
            className="with-embedded-img button with-shadow"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitterBird} alt="Twitter Logo" />
            <span>Share on Twitter</span>
          </a>
        }
        gateStatus={gateStatus}
      />
    </div>
  );
}
