import React from 'react';
import stripeLogo from '../../assets/images/stripe_logo.png';
import squareLogo from '../../assets/images/square_logo.png';
import authorizeLogo from '../../assets/images/authorize_logo.png';
import StripeConnectButton from '../PaymentGateway/StripeConnectButton';
import { gql, useQuery } from '@apollo/client';
import * as routes from '../../constants/routes';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import CompanySettingsForm from './CompanySettingsForm';

export default function PaymentSettingsPage(props) {
  const MY_COMPANY = gql`
    query MyCompany {
      myCompany {
        id
        name
        phone
        email
        logo
      }
    }
  `;
  const { loading, error, data } = useQuery(MY_COMPANY);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <Link
        style={{
          position: 'relative',
          top: '18px',
          lineHeight: 1,
          display: 'block',
        }}
        to={routes.SETTINGS}
      >
        <FiArrowLeft style={{ padding: '4px 0 0 0' }} />
        Back to Settings
      </Link>
      <br />
      <CompanySettingsForm company={data.myCompany} />
    </div>
  );
}
