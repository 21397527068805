import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { Link } from 'react-router-dom';
import appLogo from '../../assets/images/zengift-logo.png';

import * as routes from '../../constants/routes';

import history from '../../constants/history';
import { AUTH_TOKEN_KEY } from '../../constants/auth';

export default function SignUpPage(props, context) {
  const SIGN_UP = gql`
    mutation SignUp($signUpInput: SignUpInput!) {
      signUp(signUpInput: $signUpInput) {
        token
      }
    }
  `;

  const [mutation] = useMutation(SIGN_UP, {
    onCompleted(res) {
      if (res.signUp && res.signUp.token) {
        localStorage.setItem(AUTH_TOKEN_KEY, res.signUp.token);
      }
      history.push(routes.ONBOARDING_STEP_ONE);
    },
    onError(res) {
      console.log('Sign Up Err', res);
    },
  });

  const { handleSubmit, register, errors } = useForm();
  const onSubmit = (values) => {
    mutation({
      variables: {
        signUpInput: {
          email: values.email,
          password: values.password,
        },
      },
    });
  };

  return (
    <div className="sign-in-card">
      <div className="logo-container with-shadow">
        <img src={appLogo} alt="App Logo" />
      </div>
      <h3 className="title is-2">Start your 14-day free Trial!</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name="email"
          placeholder="your.email@example.com"
          ref={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'invalid email address',
            },
          })}
        />
        {errors.email && errors.email.message}

        <input
          type="password"
          name="password"
          placeholder="••••••••••••••"
          minLength="8"
          ref={register({
            required: 'Required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters',
            },
          })}
        />
        {errors.password && errors.password.message}

        <div>
          <button className="button is-primary" type="submit">
            Sign Up
          </button>
        </div>
        <small style={{ display: 'block', padding: '15px' }}>
          Got an account?{' '}
          <Link to={routes.SIGN_IN}>Sign in Instead</Link>
        </small>
      </form>
    </div>
  );
}
