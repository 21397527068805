import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import GiftCardPreview from '../GiftCard/GiftCardPreview';
import moment from 'moment';

export default function RedemptionInfo(props) {
  const redemption = props.redemption;

  return (
    <div>
      {redemption.id}
    </div>
  )
}
