import React, { useEffect } from 'react';
import * as routes from '../../constants/routes';
import cardShare from '../../assets/images/share-preview-card.png';
import { gql, useQuery, useMutation } from '@apollo/client';

import StripeConnectButton from '../PaymentGateway/StripeConnectButton';
import GiftCardPreview from '../GiftCard/GiftCardPreview';

import OnboardingRedirector from './OnboardingRedirector';

import { Link } from 'react-router-dom';

export default function OnboardingStepTwoPage(props) {
  const GET_MYSELF = gql`
    query GetMyself {
      me {
        id
        currentOnboardingStep
        email
        company {
          name
          giftCards {
            name
            value
            backgroundImageUrl
            description
          }
          paymentGateway {
            id
            isActive
          }
        }
      }
    }
  `;

  const COMPLETE_ONBOARDING_STEP = gql`
    mutation completeOnboardingStep($step: Int!) {
      completeOnboardingStep(step: $step) {
        id
      }
    }
  `;

  function handleOnboardingKeyDown(event) {
    let key = event.keyCode || event.charCode || 0;

    if ([13, 39].indexOf(key) !== -1) {
      document.querySelectorAll('.next-button')[0].click();
    } else if ([37].indexOf(key) !== -1) {
      document.querySelectorAll('.back-button')[0].click();
    }
  }

  const { data } = useQuery(GET_MYSELF);
  const [mutation, _] = useMutation(COMPLETE_ONBOARDING_STEP);

  useEffect(() => {
    document.body.addEventListener(
      'keydown',
      handleOnboardingKeyDown,
      null,
    );

    mutation({ variables: { step: 1 } });

    return function cleanup() {
      document.body.removeEventListener(
        'keydown',
        handleOnboardingKeyDown,
      );
    };
  }, []);

  if (!data || !data.me) {
    return <div>logged out</div>;
  }

  return (
    <div className="is-clearfix with-shadow onboarding-card">
      <div className="columns">
        <div className="column is-6 content-column">
          <h2 className="title is-4">
            We've already set up a default card for you to sell..
          </h2>
          <h4 className="subtitle is-5">
            All we need now is for you to connect your Stripe (so you
            can get paid)
          </h4>
          <StripeConnectButton
            me={data.me}
            isActive={data.me.company.paymentGateway.isActive}
          />
        </div>
        <div className="column is-6" style={{ marginTop: '10px' }}>
          {data.me.company.giftCards[0] ? (
            <div>
              <GiftCardPreview
                giftCard={data.me.company.giftCards[0]}
              />
              <small style={{ margin: '-5px 0 0 10px' }}>
                Don't worry - you can customize this later
              </small>
            </div>
          ) : (
            <img src={cardShare} alt="Card Style" />
          )}
        </div>
      </div>
      <Link
        to={routes.ONBOARDING_STEP_ONE}
        className="back-button button is-small"
      >
        BACK
      </Link>

      <Link
        to={routes.URL_SHARE}
        className="next-button button is-small is-primary is-pulled-right"
      >
        NEXT
      </Link>
      <Link
        to={routes.FACEBOOK_SHARE}
        className="skip-button button is-small is-pulled-right mr-m"
      >
        SKIP
      </Link>
      <OnboardingRedirector
        userStep={data.me.currentOnboardingStep}
        visitedStep={2}
      />
    </div>
  );
}
