import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';
import {
  successToast,
  errorToast,
  normalToast,
} from '../../constants/toasts';
import { ToastContainer } from 'react-toastify';
import appLogo from '../../assets/images/zengift-logo.png';
import { AUTH_TOKEN_KEY } from '../../constants/auth';
import * as routes from '../../constants/routes';

import history from '../../constants/history';

export default function NewPasswordPage(props) {
  const { token } = useParams();
  const resetPasswordToken = token;
  const RESET_PASSWORD = gql`
    mutation ResetPassword($newPasswordInput: NewPasswordInput!) {
      resetPassword(newPasswordInput: $newPasswordInput)
    }
  `;

  const [displayError, setDisplayError] = useState('');

  const [mutation] = useMutation(RESET_PASSWORD, {
    onCompleted() {
      console.log('succes oncompleted');
    },
    onError(res) {
      console.log('error');
      setDisplayError(
        'Password reset token is invalid or has expired.',
      );
    },
  });

  const { handleSubmit, register, errors } = useForm();
  const onSubmit = async (values) => {
    if (values.password === values.confPassword) {
      await mutation({
        variables: {
          newPasswordInput: {
            password: values.password,
            resetPasswordToken,
          },
        },
      }).then((res) => {
        successToast('Password has been successfuly reset');
        normalToast('Redirecting to login');
        setTimeout(function () {
          history.push(routes.SIGN_IN);
        }, 3000);
      });
    } else {
      errorToast('Passwords do not match');
    }
  };

  return (
    <div className="sign-in-card">
      <ToastContainer />

      <div className="logo-container with-shadow">
        <img src={appLogo} alt="App Logo" />
      </div>
      <h3 className="title is-2">Create your new passsword</h3>
      <h4 className="subtitle">
        <Link to={routes.SIGN_IN}>or Sign in</Link>
      </h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Your new password</label>
        <input
          type="password"
          name="password"
          placeholder="••••••••••••••"
          ref={register({})}
        />
        {errors.password && errors.password.message}
        <div>
          <label className="has-text-left">
            Please retype your new password
          </label>
          <input
            type="password"
            name="confPassword"
            placeholder="••••••••••••••"
            ref={register({})}
          />
          {errors.confPassword && errors.confPassword.message}
        </div>
        <div>
          {displayError && displayError.message}
          <button className="button is-primary" type="submit">
            Save New Password
          </button>
        </div>
      </form>
    </div>
  );
}
