import React, { useRef } from 'react';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import styled from 'styled-components';
import { DragDrop } from '@uppy/react';
import { ToastContainer } from 'react-toastify';
import { successToast, errorToast } from '../../constants/toasts';
const AwsS3 = require('@uppy/aws-s3');
const ms = require('ms');

export default function Uploader(props) {
  const UploaderComp = styled(DragDrop)``;
  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://app.yourzengift.com'
      : 'http://app.yourzengift.local:3000';
  const uppy = Uppy({
    meta: { type: 'avatar' },
    restrictions: {
      maxNumberOfFiles: 1,
    },
    autoProceed: true,
    allowMultipleUploads: true,
    onBeforeUpload(files) {
      console.log('onb4', files);
      for (const [key, file] of Object.entries(files)) {
        if (!file.type.match('image*')) {
          console.log('err', file.extension);
          errorToast('File must be an image');
          uppy.reset();
          return false;
        }
      }
    },
  });

  uppy.use(AwsS3, {
    // use the AwsS3 plugin
    fields: [], // empty array
    getUploadParameters(file) {
      // here we prepare our request to the server for the upload URL
      return fetch(`/sHvy6paopZVk`, {
        // we'll send the info asynchronously via fetch to our nodejs server endpoint, '/uploader' in this case
        method: 'POST', // all the examples I found via the Uppy site used 'PUT' and did not work
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers':
            'Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods':
            'DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT',
          'X-Requested-With': '*',
        },
        body: JSON.stringify({
          subfolders: `${props.subfolders}`,
          filename: `${props.uploadObject}-${props.companyId}`, // here we are passing data to the server/back end
          contentType: file.type,
        }),
      })
        .then((response) => {
          console.log('TO:' + typeof response);
          console.log('Response from String', response);
          return response.json(); // return the server's response as a JSON promise
        })
        .then((data) => {
          // here you can have a look at the data the server sent back - get rid of this for production!
          return {
            method: data.method, // here we send method, url, fields and headers to the AWS S3 bucket
            url: data.url,
            fields: data.fields,
            headers: data.headers,
          };
        });
    },
  });
  uppy.on('complete', (result) => {
    if (result.successful) {
      const uploadURL = result.successful[0].uploadURL;
      console.log(
        "Upload complete! We've uploaded these files:",
        uploadURL,
      ); // if upload succeeds, let's see what we uploaded
      props.currentImgRef.current.src = uploadURL;
      props.imageInputRef.current.value = uploadURL;
    } else {
      console.log('Upload error: ', result.failed); // if upload failed, let's see what went wrong
    }
  });

  return (
    <div>
      <ToastContainer />
      <DragDrop
        uppy={uppy}
        height="100%"
        locale={{
          strings: {
            // Text to show on the droppable area.
            // `%{browse}` is replaced with a link that opens the system file selection dialog.
            dropHereOr: 'Drop image here or click to upload',
            // Used as the label for the link that opens the system file selection dialog.
            browse: 'browse',
          },
        }}
      />
      <br />
    </div>
  );
}
