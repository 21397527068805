import React from 'react';
import { useForm } from 'react-hook-form';
import { gql, useMutation, useQuery } from '@apollo/client';
import * as routes from '../../constants/routes';
import history from '../../constants/history';
import EmailSettingsForm from './EmailSettingsForm';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

export default function EmailTemplateSettings(props) {
  const GET_EMAIL_TEMPLATES = gql`
    query GetEmailTemplates {
      me {
        company {
          setting {
            giftCardEmailTemplate {
              id
              fromEmail
              fromName
              subject
              title
              body
              cta
              ctaLink
              emailFooter
              logo
              settingId
            }
            receiptEmailTemplate {
              id
              fromEmail
              fromName
              subject
              title
              body
              emailFooter
              settingId
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_EMAIL_TEMPLATES);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <Link
        style={{
          position: 'relative',
          top: '18px',
          lineHeight: 1,
          display: 'block',
        }}
        to={routes.SETTINGS}
      >
        <FiArrowLeft style={{ padding: '4px 0 0 0' }} />
        Back to Settings
      </Link>
      <br />
      <EmailSettingsForm setting={data.me.company.setting} />
    </div>
  );
}
