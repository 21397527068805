import React from 'react';
import PaymentForm from './PaymentForm';
import { gql, useQuery } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';
import { useParams } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';

export default function OrderPaymentPage(props) {
  const ORDER_QUERY = gql`
    query OrderPayment($id: ID!) {
      order(id: $id) {
        id
        intentSecret
        connectedStripeAccountId
        status
        purchaserName
        recipientName
        giftCard {
          name
          description
          backgroundImageUrl
          value
        }
      }
    }
  `;

  const { uuid } = useParams();

  const { data } = useQuery(ORDER_QUERY, {
    variables: { id: uuid },
  });

  if (!data) {
    return 'Loading';
  }
  const stripeKey =
    process.env.NODE_ENV === 'production'
      ? 'pk_live_RBZmvRp5cmtuUW5t0CaMff8z'
      : 'pk_test_wZhv6xjmL3KqQzf3rIoXCv5v';
  const connectedStripeAccountId =
    data.order.connectedStripeAccountId;
  const stripePromise = loadStripe(stripeKey, {
    stripeAccount: connectedStripeAccountId,
  });

  return (
    <div className="is-clearfix with-shadow purchase-page">
      <div>
        <h2 className="title is-4">Checkout - Step 2</h2>
        <Elements stripe={stripePromise}>
          <PaymentForm order={data.order} />
        </Elements>
      </div>
    </div>
  );
}
