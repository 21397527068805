import React from 'react';
import { gql, useQuery } from '@apollo/client';

import linkify from '../../lib/linkify';

export default function StorePageLink(props) {
  const GET_MYSELF = gql`
    query StorePageLinkGetMyself {
      me {
        id
        company {
          purchasePage {
            shareLink
          }
        }
      }
    }
  `;
  const { data } = useQuery(GET_MYSELF);

  if (!data || !data.me) {
    return <div>logged out</div>;
  }

  const href = linkify(data.me.company.purchasePage.shareLink);

  return <a href={href}>Store Front</a>;
}
