import React from 'react';
import * as routes from '../../constants/routes';
import { Link } from 'react-router-dom';

export default function AddGiftCardMotivator(props) {
  if (props.anyCard) {
    return (
      <div className="box card" style={{opacity: 0.5}}>
        Have at least one active Gift Card (Done)
      </div>
    )
  }
  return (
    <Link to={routes.GIFT_CARDS}>
      <div className="box card">
        <strong>TODO:</strong> Have at least one active Gift Card
      </div>
    </Link>
  );
}

