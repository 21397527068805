import React from 'react';
import stripeLogo from '../../assets/images/stripe_logo.png';
import squareLogo from '../../assets/images/square_logo.png';
import authorizeLogo from '../../assets/images/authorize_logo.png';
import StripeConnectButton from '../PaymentGateway/StripeConnectButton';
import { gql, useQuery } from '@apollo/client';
import * as routes from '../../constants/routes';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from "react-icons/fi";

export default function PaymentSettingsPage(props) {
  const GET_MYSELF = gql`
    query PaymentSettingsGetMyself {
      me {
        id
        email
        company {
          paymentGateway {
            id
            isActive
          }
        }
      }
    }
  `;

  const { data } = useQuery(GET_MYSELF);

  if (!data || !data.me) {
    return <div>logged out</div>;
  }

  return (
    <div>
      <Link style={{position: "relative", top: "18px", lineHeight: 1, display: "block"}} to={routes.SETTINGS}><FiArrowLeft style={{padding: "4px 0 0 0"}} />Back to Settings</Link>
      <br />
      <br />

      <h4 className="title is-5">Attach Payment Gateway</h4>
      <p>
        {data.me.company.paymentGateway.isActive ?
          "Everything looks good. Stripe is connected, and you can start selling!" :
          "Before the money starts pouring in, you need to activate your Stripe integration"
        }
      </p>
      <br />
      <br />
      <div className="columns">
        <div className="column is-3">
          <StripeConnectButton
            me={data.me}
            isActive={data.me.company.paymentGateway.isActive}
          />
        </div>
        <div className="column is-3">
          <div className="image" style={{width: "120px"}}>
            <img src={stripeLogo} alt="Stripe Logo" />
          </div>
        </div>
      </div>

      <br />
      <br />
      <h4 className="title is-5">Coming soon..</h4>
      <div className="columns">
        <div className="column is-3">
          <div className="image">
            <img src={squareLogo} alt="Square Logo" />
          </div>
        </div>
        <div className="column is-3 is-offset-1">
          <div className="image" style={{ marginTop: '16px' }}>
            <img src={authorizeLogo} alt="Authorize.net Logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
