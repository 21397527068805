import React, { useEffect } from 'react';

export default function MarketingSiteRedirector(props) {
  useEffect(() => {
    setTimeout(function () {
      window.location.replace("https://get.yourzengift.com");
    }, 8000);
  });


  return (
    <div className="is-clearfix with-shadow purchase-page" style={{textAlign: "center"}}>
      <h3 className="title is-5">This store page could not be found.</h3>
      <p className="subtitle is-6">Redirecting you to the marketing site..</p>
    </div>
  );
}
