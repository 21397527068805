import React from 'react';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import history from '../../constants/history';
import GiftCardPreview from '../GiftCard/GiftCardPreview';

// import { Link, useParams } from 'react-router-dom';
import centsToValueString from '../../lib/centsToValueString';
import cardShare from '../../assets/images/share-preview-card.png';

const CREATE_GIFT_CARD_ORDER = gql`
  mutation CreateGiftCardOrder(
    $giftCardId: ID!
    $orderInput: OrderInput!
  ) {
    createGiftCardOrder(
      giftCardId: $giftCardId
      orderInput: $orderInput
    ) {
      id
      price
    }
  }
`;

export default function OrderForm(props) {
  const [createOrder] = useMutation(CREATE_GIFT_CARD_ORDER, {
    onCompleted(res) {
      console.log('Created Order:', res);
      history.push('/orders/' + res.createGiftCardOrder.id + '/pay');
    },
    onError(res) {
      console.log('Create Order Err', res);
    },
  });

  // const [error, setError] = useState(null);

  const { handleSubmit, register, setValue } = useForm();

  const cardId = props.data.company.giftCard.id;
  const cardPrice = props.data.company.giftCard.price;
  const additionalUrl = props.data.company.giftCard.additionalInfoUrl;
  const cardValue = props.data.company.giftCard.value;

  const onSubmit = async (values) => {
    createOrder({
      variables: {
        giftCardId: cardId,
        orderInput: {
          purchaserName: values.purchaserName,
          purchaserEmail: values.purchaserEmail,
          recipientName: values.recipientName,
          recipientEmail: values.recipientEmail,
        },
      },
    });
  };

  function handleFakeDataBtnClick() {
    const recipientName = `recipient-${Math.floor(
      Math.random() * 1e8,
    )}`;
    const purchaserName = `purchaser-${Math.floor(
      Math.random() * 1e8,
    )}`;

    setValue([
      { recipientName: recipientName },
      { recipientEmail: `chris.aa.king+recipient@gmail.com` },
      { purchaserName: purchaserName },
      { purchaserEmail: `chris.aa.king+purchaser@gmail.com` },
      {
        message: `Message from ${recipientName} to ${purchaserName}`,
      },
    ]);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="columns">
        <div className="column is-6 content-column">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Card Value</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input is-static"
                    value={centsToValueString(cardValue)}
                    readOnly
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Amount Paid</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input is-static"
                    value={centsToValueString(cardPrice)}
                    readOnly
                  />
                  <span className="tag is-normal is-success">
                    You Save{' '}
                    {centsToValueString(
                      Math.floor(cardValue - cardPrice),
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">To</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    name="recipientName"
                    className="input"
                    type="text"
                    placeholder="Recipient Name"
                    ref={register({
                      max: 100,
                      min: 5,
                      required: 'Required',
                    })}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Recipient Email</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    name="recipientEmail"
                    className="input"
                    type="email"
                    placeholder="recipient@example.com"
                    ref={register({
                      required: 'Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'invalid email address',
                      },
                    })}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">From</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    name="purchaserName"
                    className="input"
                    type="text"
                    placeholder="Your name"
                    ref={register({
                      max: 100,
                      min: 5,
                      required: 'Required',
                    })}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Your email</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    name="purchaserEmail"
                    className="input"
                    type="email"
                    placeholder="your.email@example.com"
                    ref={register({
                      required: 'Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'invalid email address',
                      },
                    })}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Message</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <textarea
                    name="message"
                    className="textarea"
                    placeholder="Write your message here.."
                    ref={register({
                      max: 2000,
                    })}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-6">
          <GiftCardPreview giftCard={props.data.company.giftCard} />
        </div>
      </div>
      <hr />
      <div className="columns">
        {process.env.NODE_ENV === 'development' && (
          <div className="column is-3 content-column">
            <button
              onClick={() => handleFakeDataBtnClick()}
              className="button is-primary is-pulled-right"
              type="submit"
            >
              [DEV] Fake it
            </button>
          </div>
        )}
        <div className="column is-3 content-column ">
          <a
            className={'button is-pulled-right'}
            href={additionalUrl}
            target="_blank"
          >
            More about us
          </a>
        </div>
        <div className="column is-3 content-column">
          <button
            className="button is-primary is-pulled-right"
            type="submit"
          >
            Purchase Gift Card
          </button>
        </div>
      </div>
    </form>
  );
}
