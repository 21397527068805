import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import LogoutButton from './LogoutButton';
import { ReactComponent as StoreIcon } from '../../assets/images/store-icon-01.svg';
import * as routes from '../../constants/routes';
import { Redirect } from 'react-router';

export default function ProfileWidget(props) {
  const GET_MYSELF = gql`
    query ProfileWidgetGetMyself {
      me {
        id
        avatarUrl
        currentOnboardingStep
        username
        company {
          name
        }
      }
    }
  `;

  function handleClick(ev) {
    if (
      ev.target
        .closest('.profile-widget')
        .classList.contains('is-open')
    ) {
      ev.target
        .closest('.profile-widget')
        .classList.remove('is-open');
    } else {
      ev.target.closest('.profile-widget').classList.add('is-open');
    }
  }

  const { data } = useQuery(GET_MYSELF);

  if (!data || !data.me) {
    return <div>logged out</div>;
  } else if (data.me.currentOnboardingStep === 1) {
    return <Redirect to={routes.ONBOARDING_STEP_ONE} />;
  } else if (data.me.currentOnboardingStep === 2) {
    return <Redirect to={routes.ONBOARDING_STEP_TWO} />;
  } else if (data.me.currentOnboardingStep === 3) {
    return <Redirect to={routes.FACEBOOK_SHARE} />;
  } else if (data.me.currentOnboardingStep === 4) {
    return <Redirect to={routes.LINKEDIN_SHARE} />;
  } else if (data.me.currentOnboardingStep === 5) {
    return <Redirect to={routes.TWITTER_SHARE} />;
  } else if (data.me.currentOnboardingStep === 6) {
    return <Redirect to={routes.INSTAGRAM_SHARE} />;
  }

  return (
    <div onClick={handleClick} className="profile-widget">
      <div className="avatar-wrapper">
        {data.me.avatarUrl ? (
          <img src={data.me.avatarUrl} alt="Your Profile Icon" />
        ) : (
          <StoreIcon />
        )}
      </div>
      <div className="profile-widget-body">
        <div className="profile-avatar-wrapper">
          {data.me.avatarUrl ? (
            <img src={data.me.avatarUrl} alt="Your Profile Icon" />
          ) : (
            <StoreIcon />
          )}
        </div>

        <div className="profile-info-container">
          <span>{data.me.username || data.me.email}</span>
          <small>
            {data.me.company ? data.me.company.name : 'MyBiz Inc.'}
          </small>
        </div>
        <footer>
          <LogoutButton />
        </footer>
      </div>
    </div>
  );
}
