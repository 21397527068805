import React, { useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import Cookies from 'js-cookie';

import linkify from '../../lib/linkify';

//getCompany Info by xref URL
// Add company to the H3
//Have on submit button go to individual giftcard page

const GET_PAGE_INFO = gql`
  query GetCompanyInfo($shareLink: String!) {
    purchasePageByLink(shareLink: $shareLink) {
      setting {
        company {
          name
          id
          giftCards {
            id
            name
            price
            value
          }
        }
      }
    }
  }
`;
const INCREMENT_PAGE_COUNT = gql`
  mutation IncrementPageCount($shareLink: String!) {
    incrementPageViewCount(shareLink: $shareLink) {
      metricValue
    }
  }
`;
function GiftCardsPurchaseList({ session }) {
  const pagePath = window.location.pathname;
  //CompanyInfo Query
  const { loading, error, data } = useQuery(GET_PAGE_INFO, {
    variables: { shareLink: pagePath },
  });

  const [incrementPageCount] = useMutation(INCREMENT_PAGE_COUNT);
  useEffect(() => {
    const domainString =
      process.env.NODE_ENV === 'development'
        ? 'yourzengift.local'
        : 'yourzengift.com';
    const pagePathCookie = Cookies.get('lastPurchasePageVisited', {
      domain: domainString,
    });

    if (pagePathCookie !== pagePath) {
      console.log('Updating cookie');

      Cookies.set('lastPurchasePageVisited', pagePath, {
        domain: domainString,
      });
      incrementPageCount({
        variables: {
          shareLink: pagePath,
        },
      });
    } else
      console.log(pagePathCookie, '=', pagePath, 'same pagepath');
  }, [pagePath, incrementPageCount]);

  if (error || loading) {
    if (error) {
      return `Error! ${error.message}`;
    } else return 'Loading...';
  }

  const companyData = data.purchasePageByLink.setting.company;
  const giftCardData =
    data.purchasePageByLink.setting.company.giftCards;
  const activeGiftCards = giftCardData.reduce(
    (accumulator, currentCard) => {
      if (!currentCard.isEdited && currentCard.isActive) {
        accumulator.push(currentCard);
      }
      return accumulator;
    },
    [],
  );

  const onSubmit = (id) => {
    console.log('Going to giftcard page:', id);
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <meta
        property="og:image"
        itemprop="image"
        content={linkify(
          `media/social-media-share/${
            Math.floor(Math.random() * 3) + 1
          }.png`,
        )}
      />
      <h3 className="title is-3">
        {companyData.name} Gift Cards Page
      </h3>

      <div className="columns">
        {(function () {
          if (activeGiftCards.length < 1) {
            return <div>No Cards Available At This Time</div>;
          } else {
            const giftcardElements = activeGiftCards.map((card) => {
              return (
                <div className="column" key={card.id}>
                  <div className="card box">
                    <h3 className="title is-5">{card.name}</h3>
                    <div>Price: ${card.price}</div>
                    <div>Value: ${card.value}</div>
                    <div>
                      <button onClick={() => onSubmit(card.id)}>
                        Buy Now
                      </button>
                    </div>
                  </div>
                </div>
              );
            });
            return giftcardElements;
          }
        })()}
      </div>
    </div>
  );
}
export default GiftCardsPurchaseList;
