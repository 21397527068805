import React from 'react';
import moment from 'moment';
import { GrMoney } from 'react-icons/gr';
import GiftCardPreview from '../GiftCard/GiftCardPreview';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
import centsToValueString from '../../lib/centsToValueString';

export default function OrderCard(props) {
  const order = props.order;

  return (
    <div className="card box order-card" key={order.id}>
      <div className="icon-wrapper">
        <GrMoney size={48} />
        <span className="tag is-success">{order.status}</span>
        <br />
        <br />
        {order.status === 'unredeemed' && (
          <Link
            to={`${routes.QUICK_REDEEM}?code=${order.code}`}
            className="button is-small"
          >
            Redeem..
          </Link>
        )}
        <Link
          to={routes.ORDER.replace(':uuid', order.id)}
          className="button is-small"
        >
          View
        </Link>
      </div>

      <div>
        <GiftCardPreview giftCard={order.giftCard} />
      </div>
      <div className="order-body">
        <div>
          <small>From</small>
          <div className="user-card">
            <strong>{order.purchaserName}</strong>
            <div>{order.purchaserEmail}</div>
          </div>

          <small>To</small>
          <div className="user-card">
            <strong>{order.recipientName}</strong>
            <div>{order.recipientEmail}</div>
          </div>
        </div>

        <div className="order-info with-shadow">
          <small style={{ top: '-5px' }}>Details</small>
          <br />

          <div>
            <strong>Placed:</strong>
            <time>
              {moment(parseInt(order.createdAt)).format('LLLL')}
            </time>
          </div>
          <div>
            <strong>Price:</strong>
            {centsToValueString(order.price)}
          </div>
          <div>
            <strong>Value:</strong>
            {centsToValueString(order.value)}
          </div>
          <div>
            <strong>Code:</strong>
            {order.code}
          </div>
        </div>
      </div>
    </div>
  );
}
