import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import * as routes from '../../constants/routes';
import history from '../../constants/history';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import PurchasePageSettingsForm from './PurchasePageSettingsForm';

export default function PurchasePageSettings(props) {
  const GET_PURCHASE_PAGE = gql`
    query GetPurchasePage {
      myPurchasePage {
        id
        headerBackgroundColor
        menuFont
        bodyFont
        menuLinks {
          id
          url
          anchor
        }
        shareLink
        companyId
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_PURCHASE_PAGE);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <Link
        style={{
          position: 'relative',
          top: '18px',
          lineHeight: 1,
          display: 'block',
        }}
        to={routes.SETTINGS}
      >
        <FiArrowLeft style={{ padding: '4px 0 0 0' }} />
        Back to Settings
      </Link>
      <br />
      <PurchasePageSettingsForm purchasePage={data.myPurchasePage} />
    </div>
  );
}
