import React from 'react';
import * as routes from '../../constants/routes';
import { gql, useQuery } from '@apollo/client';
import appLogo from '../../assets/images/zengift-logotype.png';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

export default function PurchasePageHeader(props) {
  let { companySlug } = useParams();

  const STORE_PAGE_QUERY = gql`
    query StorePageQuery($companySlug: String!) {
      company(slug: $companySlug) {
        id
        name
        logo
        purchasePage {
          headerBackgroundColor
          menuFont
          bodyFont
          menuLinks {
            anchor
            url
          }
        }
      }
    }
  `;
  const { data } = useQuery(STORE_PAGE_QUERY, {
    variables: { companySlug: companySlug },
  });
  if (!data) {
    return 'Loading..';
  }
  const menuLinks = data.company.purchasePage.menuLinks;
  const companyLogo = data.company.logo;
  const menuFont = data.company.purchasePage.menuFont;
  const bgColor = data.company.purchasePage.headerBackgroundColor;
  const Header = styled.header`
    font-family: '${menuFont}';
    background-color: ${bgColor};
    display: flex;
    
  `;
  return (
    <div>
      <Header className="column with-shadow header ">
        <a></a>
        <img src={companyLogo ? companyLogo : null} />

        <a href={menuLinks[0].url}>{menuLinks[0].anchor}</a>
        <a href={menuLinks[1].url}>{menuLinks[1].anchor}</a>
      </Header>
      <main className="layout-body">
        <div
          className="layout-content with-shadow"
          style={{ backgroundColor: '#1E3770' }}
        >
          {props.children}
        </div>
      </main>
    </div>
  );
}
