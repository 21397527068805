import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import stripeS from '../../assets/images/stripe-s-small.png';

const client_id = process.env.NODE_ENV === 'production' ? 'ca_HCBnjtGRCy5NvDp7aJZ9aJsW7HuULdE7' : 'ca_HCBnGdid92ISd5FSJBGiihHKwrOyk9p7';

export default function StripeConnectButton(props) {
  const UPDATE_MY_GATEWAY = gql`
    mutation UpdateMyPaymentGateway(
      $paymentGatewayInput: PaymentGatewayInput!
    ) {
      updateMyPaymentGateway(
        paymentGatewayInput: $paymentGatewayInput
      ) {
        id
      }
    }
  `;

  const [isActive, setIsActive] = useState(props.isActive);
  const [isActivating, setIsActivating] = useState(false);

  function handleStripeClick() {
    document.getElementById('stripe-button').innerHtml =
      "<span style='color:green'>Stripe Connected!</span>";
  }

  const [mutation] = useMutation(UPDATE_MY_GATEWAY, {
    onCompleted(res) {
      window.history.replaceState({}, document.title, window.location.pathname);
      setIsActivating(false);
      setIsActive(true);
    },
    onError(res) {
      console.log(res);
    },
  });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    const state = query.get('state');

    if (code && state && !isActivating) {
      setIsActivating(true);
      setTimeout(function() {
        mutation({
          variables: {
            paymentGatewayInput: { code: code, id: state },
          },
        });
      }, 2000);
    }
  });

  return (
    <div>
      {isActive ? (
        <span
          id="stripe-button"
          className="with-embedded-img button with-shadow"
        >
          <img src={stripeS} alt="Stripe Button" />
          <span>Stripe Connected!</span>
        </span>
      ) : (
        <a
          href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${client_id}&scope=read_write&stripe_user[email]=${props.me.email}&state=${props.me.company.paymentGateway.id}`}
          target="_blank"
          rel="noopener noreferrer"
          id="stripe-button"
          className="with-embedded-img button with-shadow"
          onClick={handleStripeClick}
        >
          <img src={stripeS} alt="Stripe Button" />
          {isActivating ? <span>Activating..</span> : <span>Connect Stripe</span>}
          
        </a>
      )}
    </div>
  );
}
