import React, { useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import GiftCardPreviewLink from '../GiftCard/GiftCardPreviewLink';
import MarketingSiteRedirector from './MarketingSiteRedirector';
import OnboardingProgressMotivator from '../Onboarding/OnboardingProgressMotivator';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../../assets/styles/bulma.min.scss';
import styled from 'styled-components';
import BrandingFooter from '../Layouts/BrandingFooter';
export default function StorePage(props) {
  console.log('Mounting Store page');
  let { companySlug } = useParams();

  const STORE_PAGE_QUERY = gql`
    query StorePageQuery($companySlug: String!) {
      company(slug: $companySlug) {
        id
        name
        purchasePage {
          headerBackgroundColor
          menuFont
          bodyFont
        }
        giftCards {
          id
          name
          slug
          price
          value
          description
          backgroundImageUrl
          isActive
        }
        paymentGateway {
          isActive
        }
        setting {
          isBrandingRemoved
        }
      }
    }
  `;

  const Body = styled.div`
    font-family: '${bodyFont}';
  `;

  const TRACK_VISIT_MUTATION = gql`
    mutation CreateStoreVisitEvent($companySlug: String!) {
      createStoreVisitEvent(companySlug: $companySlug)
    }
  `;
  const [trackStoreVisit] = useMutation(TRACK_VISIT_MUTATION, {
    onError(res) {
      console.log('Store Visit Tracking Err', res);
    },
  });

  useEffect(() => {
    const domainString =
      process.env.NODE_ENV === 'development'
        ? 'yourzengift.local'
        : 'yourzengift.com';
    const companySlugCookie = Cookies.get('lastStorePageVisited', {
      domain: domainString,
    });

    if (companySlugCookie !== companySlug) {
      Cookies.set('lastStorePageVisited', companySlug, {
        domain: domainString,
      });
      trackStoreVisit({
        variables: {
          companySlug: companySlug,
        },
      });
    }
  });

  const { data } = useQuery(STORE_PAGE_QUERY, {
    variables: { companySlug: companySlug },
  });

  if (!data) {
    return 'Loading..';
  }

  if (data.company === null) {
    return <MarketingSiteRedirector />;
  }

  if (
    data.company.giftCards.length === 0 ||
    !data.company.paymentGateway.isActive
  ) {
    return (
      <OnboardingProgressMotivator
        anyCard={data.company.giftCards.length > 0}
        stripeActive={data.company.paymentGateway.isActive}
      />
    );
  }

  const filtered = data.company.giftCards.reduce(
    (accumulator, currentValue) => {
      if (currentValue.isActive) {
        accumulator.push(currentValue);
      }
      return accumulator;
    },
    [],
  );
  const grouped = filtered.map((e, i) =>
    i % 2 === 0 ? filtered.slice(i, i + 2) : null,
  );
  const bodyFont = data.company.purchasePage.bodyFont;

  return (
    <Body>
      <div>
        <div className="is-clearfix with-shadow purchase-page">
          <h2 className="title is-4">
            {data.company.name}'s Gift Card Store
          </h2>
          {grouped.map((pair) => (
            <div className="columns">
              {pair &&
                pair.map((giftCard) => (
                  <div className="column">
                    <GiftCardPreviewLink
                      giftCard={giftCard}
                      companySlug={companySlug}
                    />
                  </div>
                ))}
            </div>
          ))}
          <BrandingFooter />
        </div>
      </div>
    </Body>
  );
}
