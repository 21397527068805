import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <div className="px-5">
        <h2>Privacy Policy</h2>
        We collect the e-mail addresses of those who communicate with
        us via e-mail, aggregate information on what pages consumers
        access or visit, and information volunteered by the consumer
        (such as survey information and/or site registrations). The
        information we collect is used to improve the content of our
        Web pages and the quality of our service, and is not shared
        with or sold to other organizations for commercial purposes,
        except to provide products or services you've requested, when
        we have your permission, or under the following circumstances:
        <br />
        <br />
        It is necessary to share information in order to investigate,
        prevent, or take action regarding illegal activities,
        suspected fraud, situations involving potential threats to the
        physical safety of any person, violations of Terms of Service,
        or as otherwise required by law.
        <br />
        <br />
        We transfer information about you if ZenGift is acquired by or
        merged with another company. In this event, ZenGift WILL
        notify you before information about you is transferred and
        becomes subject to a different privacy policy.
        <br />
        <br />
        <strong>Information Gathering and Usage</strong> <br />
        <br />
        When you register for the ZenGift application we ask for
        information such as your name, company name, email address,
        billing address, credit card information.
        <br />
        <br />
        ZenGift uses collected information for the following general
        purposes:
        <br />
        <br />
        Products and Services Provision, Billing, Identification and
        Authentication, Services Improvement, Contact, and Research.
        <br />
        <br />
        <strong>Cookies</strong> <br />
        <br />
        A cookie is a small amount of data, which often includes an
        anonymous unique identifier, that is sent to your browser from
        a web site's computers and stored on your computer's hard
        drive. Cookies are required to use the ZenGift application.
        <br />
        <br />
        ZenGift cookies - ZenGift uses both session and permanent
        cookies to enable us to improve your use of our website when
        you visit, such as allowing you to login to your account and
        retrieve session information.
        <br />
        <br />
        Third party cookies - In addition we also allow certain third
        parties (such as Google and other platforms) to use analytical
        and temporary tracking cookies on our website to compile
        aggregate information about the areas of our website that are
        visited most frequently and to track our own advertising. This
        information is used to enhance the content of our website,
        make your use of the website easier and to aid us in, and
        inform us of, the success of our own advertising elsewhere on
        the internet. We regard these cookies as minimally intrusive
        in terms of privacy.
        <br />
        <br />
        <strong>Data Storage</strong> <br />
        <br />
        ZenGift uses third party vendors and hosting partners to
        provide the necessary hardware, software, networking, storage,
        and related technology required to run the ZenGift
        application. Although ZenGift owns the code, databases, and
        all rights to the ZenGift application, you retain all rights
        to your data. Data shared with third parties for the purposes
        of opt-in integrations is subject to the privacy policies and
        terms of service of those services.
        <br />
        <br />
        <strong>Disclosure</strong> <br />
        <br />
        ZenGift may disclose personally identifiable information under
        special circumstances, such as to comply with subpoenas or
        when your actions violate the Terms of Service.
        <br />
        <br />
        <strong>Changes</strong> <br />
        <br />
        ZenGift may periodically update this policy. We will notify
        you about significant changes in the way we treat personal
        information by sending a notice to the primary email address
        specified in your ZenGift primary account holder account or by
        placing a prominent notice on our site.
        <br />
        <br />
        <strong>Questions</strong> <br />
        <br />
        Any questions about this privacy policy should be addressed
        to: Amar@ZenGift.com
      </div>
    </div>
  );
}
