import React, { useState } from 'react';

export default function WelcomeMessage(props) {
  const [isVisible, setIsVisible] = useState(true)

  const handleClick = () => setIsVisible(false)
  
  return (
    <article className="message is-success" style={{display: `${isVisible ? 'block' : 'none'}`}}>
      <div className="message-body">
        <button onClick={handleClick} className="delete is-pulled-right" aria-label="delete"></button>
        Welcome to your dashboard! This is the place where you can track your gift cards' performance at a glance and browse your recent orders.
      </div>
    </article>
  );
}
