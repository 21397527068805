import React from 'react';
import * as routes from '../../constants/routes';
import appLogo from '../../assets/images/zengift-logotype.png';
import { linkLogo } from './utils';
import { Link } from 'react-router-dom';
import linkify from '../../lib/linkify';
import { date } from 'faker';
import Footer from './Footer';

const OnboardingLayout = (props) => {
  let logo;
  if (linkLogo(window.location.pathname)) {
    console.log(
      'This is an onboarding page',
      window.location.pathname,
    );

    logo = <img src={appLogo} alt="Your Logo" />;
  } else {
    console.log('Not an onboarding page');

    logo = (
      <a href={linkify(routes.DASHBOARD, true)}>
        <img src={appLogo} alt="Your Logo" />
      </a>
    );
  }
  return (
    <div>
      <header className="with-shadow bg-gradient-turquoise">
        {logo}
      </header>
      <main className="layout-body">
        <div
          className="layout-content with-shadow"
          style={{ backgroundColor: '#1E3770' }}
        >
          {props.children}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default OnboardingLayout;
