import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import GiftCardPreview from '../GiftCard/GiftCardPreview';
import RedemptionInfo from '../Redemptions/RedemptionInfo';
import moment from 'moment';

export default function OrderPage(props) {
  const ORDER_QUERY = gql`
    query Order($id: ID!) {
      order(id: $id) {
        id
        status
        purchaserName
        purchaserEmail
        recipientName
        code
        recipientEmail
        giftCard {
          backgroundImageUrl
          value
          name
          description
        }
        redemption {
          id
        }
      }
    }
  `;

  const { uuid } = useParams();

  const { data } = useQuery(ORDER_QUERY, {
    variables: { id: uuid },
  });

  if (!data) {
    return 'Loading Order..';
  }

  if (data && data.order == null) {
    return <div>This order could not be found</div>;
  }

  const order = data.order;

  return (
    <div className="is-clearfix with-shadow purchase-page">
      <div className="columns">
        <div className="column">
          <h2 className="title is-4">Order Detail</h2>

          <div>
            <p>Gift Card Code:</p>
            <p>
              <p className="tag is-light">{order.code}</p>
            </p>
          </div>

          <br />
          <p>
            <strong>Placed:</strong>{' '}
            {moment(Date(order.createdAt)).format('LLLL')}
          </p>
          <br />
          <p>
            <strong>Status: </strong>
            <span className="tag is-success">{order.status}</span>
          </p>
          <br />
          {order.redemption && (
            <RedemptionInfo redemption={order.redemption} />
          )}
          <div className="order-body">
            <div>
              <small>
                <strong>From:</strong>
              </small>
              <div className="user-card">
                <strong>{order.purchaserName}</strong>
                <div>{order.purchaserEmail}</div>
              </div>
              <br />
              <small>
                <strong>To:</strong>
              </small>
              <div className="user-card">
                <strong>{order.recipientName}</strong>
                <div>{order.recipientEmail}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <br />
          <GiftCardPreview giftCard={order.giftCard} />
        </div>
      </div>
    </div>
  );
}
