import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useWindowSize } from '@react-hook/window-size';
import cardShare from '../../assets/images/share-preview-card.png';
import successIcon from '../../assets/images/checkmark_icon.png';
import Confetti from 'react-confetti';
import GiftCardPreview from '../GiftCard/GiftCardPreview';

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

export default function PaymentForm(props) {
  const [error, setError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [confettiRunning, setConfettiRunning] = useState(true);

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };
  const { handleSubmit } = useForm();

  if (
    props.order.status == 'unredeemed' ||
    props.order.status == 'redeemed'
  ) {
    setPaymentSuccess(true);
  }

  const [width, height] = useWindowSize();

  const handlePaymentSuccess = () => {
    setPaymentSuccess(true);
    setConfettiRunning(true);
    setTimeout(function () {
      setConfettiRunning(false);
    }, 5000);
  };

  const onSubmit = async (_, ev) => {
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      // Inform the user if there was an error.
      console.log(result.error);
      setError(result.error.message);
    } else {
      setError(null);

      console.log(props.order.intentSecret);
      stripe
        .confirmCardPayment(props.order.intentSecret, {
          payment_method: {
            card: card,
            billing_details: {
              name: props.order.purchaserName,
            },
          },
        })
        .then(function (result) {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
          } else {
            // The payment has been processed!
            console.log('Processed!');
            console.log(result);
            if (result.paymentIntent.status === 'succeeded') {
              console.log('Success!!!');
              handlePaymentSuccess();
            }
          }
        });
    }
  };

  if (paymentSuccess) {
    return (
      <div style={{ textAlign: 'center', padding: '30px' }}>
        <img width="120" src={successIcon} alt="Green Checkmark" />
        <h3 className="title is-4">
          Thanks for supporting your local businesses!
        </h3>
        <p style={{ marginTop: '-20px' }}>
          Your payment has been processed successfully. You will
          receive an email receipt shortly and your gift card is
          already on the way to {props.order.recipientName}'s Inbox!
        </p>
        <small
          style={{
            opacity: 0.6,
            marginTop: '30px',
            display: 'block',
            color: '#888',
          }}
        >
          You can close this window now
        </small>

        <Confetti
          recycle={confettiRunning}
          width={width}
          height={height}
        />
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="columns">
        <div className="column is-6 content-column">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Card Number</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className="stripe-input"></div>
                  <CardNumberElement
                    id="card-element"
                    onChange={handleChange}
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: 'silver',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Expiry Date</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className="stripe-input is-small"></div>
                  <CardExpiryElement
                    id="card-element"
                    onChange={handleChange}
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: 'silver',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">CVC</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className="stripe-input is-small"></div>
                  <CardCvcElement
                    id="card-element"
                    onChange={handleChange}
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: 'silver',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            className="button is-primary is-pulled-right"
            type="submit"
            id="stripe-button"
          >
            Purchase Gift Card
          </button>
        </div>

        <div className="column is-6">
          <GiftCardPreview giftCard={props.order.giftCard} />
        </div>
      </div>
    </form>
  );
}
