import React, { useEffect } from 'react';
import * as routes from '../../constants/routes';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import history from '../../constants/history';
import OnboardingRedirector from './OnboardingRedirector';
import { GET_MY_SHARELINK } from '../../queries/currentUser';

export default function OnboardingStepOnePage(props) {
  const CREATE_COMPANY = gql`
    mutation createOrUpdateCompany($name: String!) {
      createOrUpdateCompany(name: $name) {
        id
      }
    }
  `;

  function handleOnboardingKeyDown(event) {
    let key = event.keyCode || event.charCode || 0;

    if ([13, 39].indexOf(key) !== -1) {
      document.querySelectorAll('.next-button')[0].click();
    }
  }

  useEffect(() => {
    document.body.addEventListener(
      'keydown',
      handleOnboardingKeyDown,
      null,
    );

    return function cleanup() {
      document.body.removeEventListener(
        'keydown',
        handleOnboardingKeyDown,
      );
    };
  });

  const { data } = useQuery(GET_MY_SHARELINK);
  const { handleSubmit, register } = useForm();
  const [mutation, { loading, error }] = useMutation(CREATE_COMPANY, {
    onCompleted(res) {
      history.push(routes.ONBOARDING_STEP_TWO);
    },
    onError(res) {
      console.log('Creating Company Err', res);
    },
  });
  if (loading || !data) return <p>Loading</p>;
  if (error) return <p>An error occurred</p>;
  const onSubmit = (values) => {
    console.log('values', values);

    mutation({ variables: { name: values.name } });
  };

  return (
    <div className="is-clearfix with-shadow onboarding-card">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="columns">
          <div className="column is-9 content-column">
            <h2 className="title is-4">
              <span style={{ fontWeight: 'normal' }}>
                Our goal is to help make you $500 in gift card sales.
              </span>
              <br />
              <span
                className="text-gradient-light-blue"
                style={{
                  fontSize: '120%',
                  marginTop: '10px',
                  display: 'block',
                }}
              >
                Right now.
              </span>
            </h2>
            <h4
              className="subtitle is-5"
              style={{ marginTop: '20px', marginBottom: '4px' }}
            >
              First: what's the name of your business?
            </h4>
            <p>
              Based on this business name we'll create your custom
              ZenGift URL
            </p>
            <div className="field">
              <input
                name="name"
                className="input"
                type="text"
                placeholder="Cleaning For a Reason"
                minLength="5"
                maxLength="50"
                ref={register({
                  required: true,
                  minLength: {
                    value: 5,
                    message:
                      'This needs to be more than 5 characters long',
                  },
                  maxLength: {
                    value: 50,
                    message:
                      'This can be no longer than 50 characters',
                  },
                })}
              />
            </div>
          </div>
          <div className="column is-3"></div>
        </div>
        <button
          className="skip-button next-button button is-small is-primary is-pulled-right"
          type="submit"
        >
          Next
        </button>
      </form>
      <OnboardingRedirector
        userStep={data.me.currentOnboardingStep}
        visitedStep={1}
      />
    </div>
  );
}
