import React from 'react';
import * as routes from '../../constants/routes';
import { Redirect } from 'react-router-dom';

export default function OnboardingRedirector(props) {
  const { userStep, visitedStep } = props;

  // Do not redirect if visiting current step or next one
  if (
    userStep === visitedStep ||
    userStep === visitedStep - 1 ||
    userStep <= 7
  ) {
    return '';
  }

  // Did not complete onboarding
  if (userStep === 1) {
    return <Redirect to={routes.ONBOARDING_STEP_ONE} />;
  } else if (userStep === 2) {
    return <Redirect to={routes.ONBOARDING_STEP_TWO} />;
  } else if (userStep === 3) {
    return <Redirect to={routes.URL_SHARE} />;
  } else if (userStep === 4) {
    return <Redirect to={routes.FACEBOOK_SHARE} />;
  } else if (userStep === 5) {
    return <Redirect to={routes.LINKEDIN_SHARE} />;
  } else if (userStep === 6) {
    return <Redirect to={routes.TWITTER_SHARE} />;

    // Completed Onboarding
  } else if (userStep > 6) {
    if (visitedStep === 1) {
      return <Redirect to={routes.COMPANY_SETTINGS} />;
    } else if (visitedStep === 2) {
      const query = new URLSearchParams(window.location.search);
      const code = query.get('code');
      const state = query.get('state');
      if (code && state) {
        return (
          <Redirect
            to={`${routes.PAYMENT_SETTINGS}?code=${code}&state=${state}`}
          />
        );
      } else {
        return <Redirect to={routes.PAYMENT_SETTINGS} />;
      }
    } else {
      return <Redirect to={routes.INSTAGRAM_SHARE} />;
    }
  }
}
